import { FieldBrand, ItemRecordID } from '../../../types';

import { UserUuid } from '../../users/usersTypes';

export type JobScorecardEvaluationRecordId = FieldBrand<
  ItemRecordID,
  'JobScorecardEvaluationRecordId'
>;

export type JobScorecardEvaluationUserUuid = FieldBrand<
  UserUuid,
  'JobScorecardEvaluationUserUuid'
>;

export const enum JobScorecardEvaluationIndexFields {
  ID = 'id',
  RECORD_ID = 'recordId',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  CREATED_AT = 'createdAt',

  USER_UUID = 'userUuid'
}
