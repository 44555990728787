import {
  Box,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react';
import { useState } from 'react';
import { Button } from '../../../../../../../../helpers/Button';
import { Text } from '../../../../../../../../helpers/Text';
import { ChevronDownIcon } from '../../../../../../../../icons/ChevronDownIcon';
import {
  WorkflowStageWorkflowStageTask,
  WorkflowStageWorkflowStageTasks
} from '../../../../../../../workflowStages/workflowStagesTypes';
import { useViewJobCandidateDetailsStageTasksWidgetContext } from '../../../../contexts/ViewJobCandidateDetailsStageTasksWidgetContext';

interface ViewJobCandidateDetailsTasksDropdownProps {
  workflowStageTasks: WorkflowStageWorkflowStageTasks;
}

export default function ViewJobCandidateDetailsTasksDropdown({
  workflowStageTasks
}: ViewJobCandidateDetailsTasksDropdownProps) {
  const [selectedActivity, setSelectecActivity] =
    useState<WorkflowStageWorkflowStageTask>();

  const { taskStatus, selectedTask, selectedTaskAction } =
    useViewJobCandidateDetailsStageTasksWidgetContext();

  const { isOpen, onToggle, onClose } = useDisclosure();

  const selectActivity = (activity: WorkflowStageWorkflowStageTask) => {
    setSelectecActivity(activity);

    onClose();
  };

  /* const hasSelectedAction =
    selectedTask && selectedTaskAction?.split('__').includes(selectedTask); */

  return (
    <Popover isOpen={isOpen} onClose={onClose} isLazy>
      <PopoverTrigger>
        <Flex w="full">
          <Button
            as={
              selectedTask &&
              selectedTaskAction?.split('__').includes(selectedTask)
                ? 'section'
                : undefined
            }
            w="full"
            p={0}
            h="100%"
            minH={6}
            minW={0}
            size="small"
            display="flex"
            cursor={
              taskStatus.label === 'in-progress' ? 'not-allowed' : 'pointer'
            }
            whiteSpace="nowrap"
            alignItems="stretch"
            textOverflow="ellipsis"
            wordBreak="break-all"
            onClick={(e) => {
              e.stopPropagation();

              if (taskStatus.label !== 'in-progress') {
                onToggle();
              }
            }}
            border="none"
            textAlign="left"
            boxShadow="none"
            fontSize="inherit"
            hierarchy="unstyled"
            aria-label="Dropdown"
          >
            <Flex
              w="full"
              gap={1}
              whiteSpace="nowrap"
              wordBreak="break-all"
              alignItems="center"
              fontSize="inherit"
            >
              <Text
                noOfLines={1}
                whiteSpace="nowrap"
                wordBreak="break-all"
                textOverflow="ellipsis"
                textTransform="capitalize"
                display="inline-block"
                fontWeight={selectedActivity?.name ? 'medium' : 'normal'}
                color={selectedActivity?.name ? 'inherit' : 'gray.500'}
              >
                {selectedActivity?.name ?? 'Select activity'}
              </Text>

              <ChevronDownIcon ml="auto" fill="gray.500" />
            </Flex>

            {/* {hasSelectedAction ? (
              <ViewJobCandidateDetailsSelectedTaskTool />
            ) : (
              <Flex
                w="full"
                gap={1}
                whiteSpace="nowrap"
                wordBreak="break-all"
                alignItems="center"
                fontSize="inherit"
              >
                <Text
                  noOfLines={1}
                  color="inherit"
                  whiteSpace="nowrap"
                  fontWeight="medium"
                  wordBreak="break-all"
                  textOverflow="ellipsis"
                  textTransform="capitalize"
                  display="inline-block"
                >
                  {selectedActivity?.name ?? workflowStageTasks?.[0]?.name}
                </Text>

                <ChevronDownIcon ml="auto" fill="gray.500" />
              </Flex>
            )} */}
          </Button>
        </Flex>
      </PopoverTrigger>

      <PopoverContent p={1} minH={8} minW={48} maxW={40} overflow="hidden">
        <Flex flexDir="column" overflow="auto">
          {workflowStageTasks.map((activity) => (
            <Tooltip
              label={activity.name.length > 20 ? activity.name : undefined}
              key={activity.nanoId}
            >
              <Flex>
                <Button
                  px={2}
                  py={1}
                  h="auto"
                  w="full"
                  gap={2}
                  size="small"
                  display="flex"
                  justifyContent="flex-start"
                  color="inherit"
                  textAlign="left"
                  lineHeight={1.5}
                  fontSize="inherit"
                  borderRadius="none"
                  fontWeight="inherit"
                  hierarchy="unstyled"
                  textTransform="capitalize"
                  isDisabled={selectedTask === activity.nanoId}
                  onClick={() => {
                    selectActivity(activity);
                  }}
                  value={activity.nanoId}
                  key={activity.nanoId}
                  _hover={{
                    bg: 'gray.100'
                  }}
                >
                  <Box
                    display="block"
                    overflow="hidden"
                    wordBreak="break-all"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                  >
                    {activity.name}
                  </Box>
                </Button>
              </Flex>
            </Tooltip>
          ))}
        </Flex>
      </PopoverContent>
    </Popover>
  );
}
