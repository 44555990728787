import {
  MayBe,
  FieldBrand,
  DateTime,
  ItemID,
  ItemUUID,
  ItemNanoId,
  ItemCreatedAt
} from '../../../types';

import {
  CityId,
  CityNanoId,
  CityName,
  CityLabel
} from '../../cities/citiesTypes';

import {
  CompanyId,
  CompanyNanoId,
  CompanyName
} from '../../companies/companiesTypes';

import { JobCandidateId } from '../../jobCandidates/jobCandidatesTypes';

import { JobResumeId } from '../../jobResumes/jobResumesTypes';

import { JobResumeRecruiterId } from '../../jobResumeRecruiters/jobResumeRecruitersTypes';

import {
  StateId,
  StateNanoId,
  StateName,
  StateIsoCode
} from '../../states/statesTypes';

import {
  UserId,
  UserNanoId,
  UserName,
  UserImage
} from '../../users/usersTypes';

export type EducationId = FieldBrand<ItemID, 'EducationId'>;
export type EducationUuid = FieldBrand<ItemUUID, 'EducationUuid'>;
export type MayBeEducationUuid = MayBe<EducationUuid>;
export type EducationNanoId = FieldBrand<ItemNanoId, 'EducationNanoId'>;
export type MayBeEducationNanoId = MayBe<EducationNanoId>;
export type EducationDegree = FieldBrand<string, 'EducationDegree'>;
export type EducationSchoolName = FieldBrand<string, 'EducationSchoolName'>;
export type EducationStudyField = FieldBrand<string, 'EducationStudyField'>;
export type EducationSchoolType = FieldBrand<string, 'EducationSchoolType'>;
export type EducationStartDate = FieldBrand<DateTime, 'EducationStartDate'>;
export type MayBeEducationStartDate = MayBe<EducationStartDate>;
export type EducationEndDate = FieldBrand<DateTime, 'EducationEndDate'>;
export type MayBeEducationEndDate = MayBe<EducationEndDate>;
export type EducationUserId = FieldBrand<UserId, 'EducationUserId'>;
export type EducationUser = FieldBrand<
  {
    id: UserId;
    nanoId: UserNanoId;
    name: UserName;
    image: UserImage;
  },
  'EducationUser'
>;
export type EducationCompanyId = FieldBrand<CompanyId, 'EducationCompanyId'>;
export type MayBeEducationCompanyId = MayBe<EducationCompanyId>;
export type EducationCompany = FieldBrand<
  {
    id: CompanyId;
    nanoId: CompanyNanoId;
    name: CompanyName;
  },
  'EducationCompany'
>;
export type MayBeEducationCompany = MayBe<EducationCompany>;
export type EducationStateId = FieldBrand<StateId, 'EducationStateId'>;
export type MayBeEducationStateId = MayBe<EducationStateId>;
export type EducationState = FieldBrand<
  {
    id: StateId;
    nanoId: StateNanoId;
    name: StateName;
    isoCode: StateIsoCode;
  },
  'EducationState'
>;
export type MayBeEducationState = MayBe<EducationState>;
export type EducationCityId = FieldBrand<CityId, 'EducationCityId'>;
export type MayBeEducationCityId = MayBe<EducationCityId>;
export type EducationCity = FieldBrand<
  {
    id: CityId;
    nanoId: CityNanoId;
    name: CityName;
    label: CityLabel;
  },
  'EducationCity'
>;
export type MayBeEducationCity = MayBe<EducationCity>;
export type EducationJobCandidateId = FieldBrand<
  JobCandidateId,
  'EducationJobCandidateId'
>;
export type MayBeEducationJobCandidateId = MayBe<EducationJobCandidateId>;
export type EducationJobResumeId = FieldBrand<
  JobResumeId,
  'EducationJobResumeId'
>;
export type MayBeEducationJobResumeId = MayBe<EducationJobResumeId>;
export type EducationJobResumeRecruiterId = FieldBrand<
  JobResumeRecruiterId,
  'EducationJobResumeRecruiterId'
>;
export type MayBeEducationJobResumeRecruiterId =
  MayBe<EducationJobResumeRecruiterId>;
export type EducationCreatedAt = FieldBrand<
  ItemCreatedAt,
  'EducationCreatedAt'
>;

export const enum EducationFields {
  ID = 'id',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  DEGREE = 'degree',
  SCHOOL_NAME = 'schoolName',
  STUDY_FIELD = 'studyField',
  SCHOOL_TYPE = 'schoolType',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  JOB_CANDIDATE_ID = 'jobCandidateId',
  JOB_RESUME_ID = 'jobResumeId',
  JOB_RESUME_RECRUITER_ID = 'jobResumeRecruiterId',
  USER_ID = 'userId',
  USER = 'user',
  COMPANY_ID = 'companyId',
  COMPANY = 'company',
  STATE_ID = 'stateId',
  STATE = 'state',
  CITY_ID = 'cityId',
  CITY = 'city',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  CONTACT_ID = 'contactId'
}
