import { usePaginatedItems } from '../../../common/hooks/usePaginatedItems';

import {
  FetchJobScorecardEvaluationsQuery,
  FetchJobScorecardEvaluationsCacheKey,
  FetchJobScorecardEvaluationsTotalCount,
  FetchJobScorecardEvaluationsPage,
  FetchJobScorecardEvaluationsPageSize,
  FetchJobScorecardEvaluationsError,
  FetchJobScorecardEvaluationsErrorMessage,
  FetchJobScorecardEvaluationsIsLoading,
  FetchJobScorecardEvaluationsIsFetched,
  FetchJobScorecardEvaluationsIsPlaceholderData,
  FetchJobScorecardEvaluationsEnabled,
  FetchJobScorecardEvaluationsPrefetchNextPage,
  FetchJobScorecardEvaluationsRefetchInterval,
  FetchJobScorecardEvaluationsCountType,
  FetchJobScorecardEvaluationsFilters,
  FetchJobScorecardEvaluationsSort,
  FetchJobScorecardEvaluationID,
  FetchJobScorecardEvaluationQuery,
  FetchJobScorecardEvaluationCacheKey,
  FetchJobScorecardEvaluationsMeta
} from '../../jobScorecardEvaluationsTypes';

import { JobScorecardEvaluationsBffRequests } from '../../JobScorecardEvaluationsBffRequests';

interface PaginatedJobScorecardEvaluationsBaseOptions<
  PaginatedJobScorecardEvaluationsResponseType
> {
  cacheKey: FetchJobScorecardEvaluationsCacheKey;
  query: FetchJobScorecardEvaluationsQuery;
  countType?: FetchJobScorecardEvaluationsCountType;
  initialPage?: FetchJobScorecardEvaluationsPage;
  initialPageSize?: FetchJobScorecardEvaluationsPageSize;
  initialFilters?: FetchJobScorecardEvaluationsFilters;
  initialSort?: FetchJobScorecardEvaluationsSort;
  enabled?: FetchJobScorecardEvaluationsEnabled;
  prefetchNextPage?: FetchJobScorecardEvaluationsPrefetchNextPage;
  refetchInterval?: FetchJobScorecardEvaluationsRefetchInterval;
  placeholderJobScorecardEvaluations?: PaginatedJobScorecardEvaluationsResponseType[];
  placeholderJobScorecardEvaluationsCount?: FetchJobScorecardEvaluationsTotalCount;
  placeholderData?: () => PaginatedJobScorecardEvaluationsResponse<PaginatedJobScorecardEvaluationsResponseType>;
}

interface PaginatedJobScorecardEvaluationsOptionsWithoutItem {
  fetchItemCacheKey?: never;
  itemQuery?: never;
}

interface PaginatedJobScorecardEvaluationsOptionsWithItem {
  fetchItemCacheKey: FetchJobScorecardEvaluationCacheKey;
  itemQuery: FetchJobScorecardEvaluationQuery;
}

type PaginatedJobScorecardEvaluationsOptions<
  PaginatedJobScorecardEvaluationsResponseType
> = PaginatedJobScorecardEvaluationsBaseOptions<PaginatedJobScorecardEvaluationsResponseType> &
  (
    | PaginatedJobScorecardEvaluationsOptionsWithoutItem
    | PaginatedJobScorecardEvaluationsOptionsWithItem
  );

interface PaginatedJobScorecardEvaluationsResponse<
  PaginatedJobScorecardEvaluationsResponseType
> {
  data: PaginatedJobScorecardEvaluationsResponseType[];
  totalCount: FetchJobScorecardEvaluationsTotalCount;
}

function usePaginatedJobScorecardEvaluations<
  PaginatedJobScorecardEvaluationsResponseType
>({
  cacheKey,
  query,
  countType,
  initialPage,
  initialPageSize,
  initialFilters,
  initialSort,
  enabled,
  prefetchNextPage,
  refetchInterval,
  placeholderJobScorecardEvaluations,
  placeholderJobScorecardEvaluationsCount,
  placeholderData,
  fetchItemCacheKey,
  itemQuery
}: PaginatedJobScorecardEvaluationsOptions<PaginatedJobScorecardEvaluationsResponseType>) {
  const {
    items,
    itemsTotalCount,
    itemsMeta,
    itemsError,
    itemsErrorMessage,
    itemsIsFetched,
    itemsIsLoading,
    itemsIsPlaceholderData,
    itemsCurrentPage,
    itemsCurrentPageSize,
    itemsCurrentFilters,
    itemsCurrentSort,
    fetchItems,
    paginateItems,
    changeItemsPageSize,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    sortItems,
    prefetchItems,
    prefetchItem
  } = usePaginatedItems<PaginatedJobScorecardEvaluationsResponseType>({
    cacheKey,
    initialPage,
    initialPageSize,
    initialFilters,
    initialSort,
    enabled,
    prefetchNextPage,
    refetchInterval,
    placeholderItems: placeholderJobScorecardEvaluations,
    placeholderItemsCount: placeholderJobScorecardEvaluationsCount,
    placeholderData,
    queryFn: (params) =>
      JobScorecardEvaluationsBffRequests.fetchJobScorecardEvaluations<PaginatedJobScorecardEvaluationsResponseType>(
        {
          query,
          countType,
          page: params.page as FetchJobScorecardEvaluationsPage,
          pageSize: params.pageSize as FetchJobScorecardEvaluationsPageSize,
          filters: params.filters as FetchJobScorecardEvaluationsFilters,
          sort: params.sort as FetchJobScorecardEvaluationsSort
        }
      ),
    ...(itemQuery
      ? {
          fetchItemCacheKey,
          queryItemFn: (itemId) =>
            JobScorecardEvaluationsBffRequests.fetchJobScorecardEvaluation(
              itemId as FetchJobScorecardEvaluationID,
              {
                query: itemQuery
              }
            )
        }
      : {})
  });

  return {
    jobScorecardEvaluations:
      items as PaginatedJobScorecardEvaluationsResponseType[],
    jobScorecardEvaluationsTotalCount:
      itemsTotalCount as FetchJobScorecardEvaluationsTotalCount,
    jobScorecardEvaluationsMeta: itemsMeta as FetchJobScorecardEvaluationsMeta,
    jobScorecardEvaluationsError:
      itemsError as FetchJobScorecardEvaluationsError,
    jobScorecardEvaluationsErrorMessage:
      itemsErrorMessage as FetchJobScorecardEvaluationsErrorMessage,
    jobScorecardEvaluationsIsLoading:
      itemsIsLoading as FetchJobScorecardEvaluationsIsLoading,
    jobScorecardEvaluationsIsFetched:
      itemsIsFetched as FetchJobScorecardEvaluationsIsFetched,
    jobScorecardEvaluationsIsPlaceholderData:
      itemsIsPlaceholderData as FetchJobScorecardEvaluationsIsPlaceholderData,
    jobScorecardEvaluationsCurrentPage:
      itemsCurrentPage as FetchJobScorecardEvaluationsPage,
    jobScorecardEvaluationsCurrentPageSize:
      itemsCurrentPageSize as FetchJobScorecardEvaluationsPageSize,
    jobScorecardEvaluationsCurrentFilters:
      itemsCurrentFilters as FetchJobScorecardEvaluationsFilters,
    jobScorecardEvaluationsCurrentSort:
      itemsCurrentSort as FetchJobScorecardEvaluationsSort,
    fetchJobScorecardEvaluations: fetchItems,
    paginateJobScorecardEvaluations: paginateItems,
    changeJobScorecardEvaluationsPageSize: changeItemsPageSize,
    filterJobScorecardEvaluations: filterItems,
    changeJobScorecardEvaluationsFilters: changeItemsFilters,
    clearJobScorecardEvaluationsFilters: clearItemsFilters,
    sortJobScorecardEvaluations: sortItems,
    prefetchJobScorecardEvaluations: prefetchItems,
    prefetchJobScorecardEvaluation: prefetchItem
  };
}

export default usePaginatedJobScorecardEvaluations;
