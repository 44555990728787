import React, { useCallback } from 'react';

import { FetchSkillBadgesCacheKey } from '../../skillBadgesTypes';

import { FetchSkillBadgesResponse } from '../../queries/fetchSkillBadges.query';

import { useDeleteSkillBadge } from '../../hooks/useDeleteSkillBadge';

import { SkillBadge } from '../../helpers/SkillBadge';

interface SkillBadgeWrapperProps {
  skillBadge: FetchSkillBadgesResponse;
  isEditing: boolean;
  skillBadgesCacheKey: FetchSkillBadgesCacheKey;
  onEdit: (skillBadge: FetchSkillBadgesResponse) => void;
  withoutPortal?: boolean;
  withoutDeleteButton?: boolean;
  isApplicantWorkflow?: boolean;
  allowEditing?: boolean;
  maxSkillBadgesToShow?: number;
  isJobCandidate?: boolean;
}

function SkillBadgeWrapper({
  skillBadge,
  isEditing,
  skillBadgesCacheKey,
  onEdit,
  withoutPortal,
  withoutDeleteButton,
  isApplicantWorkflow,
  allowEditing,
  maxSkillBadgesToShow,
  isJobCandidate
}: SkillBadgeWrapperProps) {
  const { deleteSkillBadgeIsLoading, deleteSkillBadge } = useDeleteSkillBadge({
    skillBadgeNanoId: skillBadge.nanoId,
    cacheKeys: [skillBadgesCacheKey]
  });

  const handleEdit = useCallback<() => void>(
    () => onEdit(skillBadge),
    [skillBadge, onEdit]
  );

  const handleDeleteSkillBadge = useCallback<() => void>(
    () => deleteSkillBadge({}),
    [deleteSkillBadge]
  );

  return (
    <SkillBadge
      dateCreated={skillBadge.createdAt}
      name={skillBadge.skill?.name || null}
      experience={skillBadge.experience}
      rating={skillBadge.rating}
      certified={skillBadge.certified}
      skillLicense={skillBadge.skillLicense}
      requirements={skillBadge.requirements}
      employmentSkillBadges={skillBadge.employmentSkillBadges}
      isEditing={isEditing || deleteSkillBadgeIsLoading}
      onEdit={handleEdit}
      onDelete={handleDeleteSkillBadge}
      withoutPortal={withoutPortal}
      experienceType={skillBadge.experienceType}
      withoutDeleteButton={withoutDeleteButton}
      isApplicantWorkflow={isApplicantWorkflow}
      allowEditing={allowEditing}
      maxSkillBadgesToShow={maxSkillBadgesToShow}
      isJobCandidate={isJobCandidate}
    />
  );
}

export default SkillBadgeWrapper;
