import { Box, Flex, GridItem } from '@chakra-ui/react';
import { TextareaField } from '../../../../../../../../helpers/forms/formFields/TextareaField';
import { Heading } from '../../../../../../../../helpers/Heading';
import { SimpleModal } from '../../../../../../../../helpers/SimpleModal';
import { IsHidden, ModalIsOpen } from '../../../../../../../../types';
import { OverAllEvaluationFormField } from '../../../../../../../common/components/formFields/InterviewScorecardFormField';
import {
  JobId,
  JobJobTitle,
  JobName,
  JobNanoId
} from '../../../../../../jobsTypes';
import { ViewJobCandidateInterviewScoreCardsPage } from '../../../../pages/ViewJobCandidateInterviewScoreCardsPage';
import { JobCandidateDetailsJobCandidate } from '../../../../ViewJobCandidateDetailsPage.types';
import { ViewJobCandidateDetailsBio } from '../../../ViewJobCandidateDetailsBio';

interface JobDetails {
  id: JobId;
  nanoId: JobNanoId;
  jobTitle: JobJobTitle;
  name: JobName;
}

const enum ScoreCard {
  EVALUATION = 'name',
  NEXT_STEP = 'nextStep'
}

interface ViewJobCandidateInterviewScoreCardModalProps {
  isOpenScoreCard: boolean;
  requiresCredit?: boolean;
  closeScoreCard: () => void;
  job: JobDetails;
  jobCandidate?: JobCandidateDetailsJobCandidate;
}

function ViewJobCandidateInterviewScoreCardModal({
  isOpenScoreCard,
  closeScoreCard,
  requiresCredit,
  jobCandidate,
  job
}: ViewJobCandidateInterviewScoreCardModalProps) {
  const evaluation = [
    { id: 1, name: 'Evaluation 1' },
    { id: 2, name: 'Evaluation 2' },
    { id: 3, name: 'Evaluation 3' }
  ];

  const nextStep = [
    { id: 1, name: 'Step 1' },
    { id: 2, name: 'Step 2' },
    { id: 3, name: 'Step 3' }
  ];

  return (
    <>
      <SimpleModal
        size="3xl"
        isOpen={isOpenScoreCard as ModalIsOpen}
        onCancel={closeScoreCard}
        returnFocusOnClose={false}
        closeButtonIsHidden={true as IsHidden}
        headerBg="gray.100"
        px={0}
        pb={0}
        header={
          <Box>
            <Heading level="h4">
              <Flex gap={3} alignItems="center" pl={'20px'} pb={'15px'}>
                <Box as="span">Candidate Interview Scorecard </Box>
              </Flex>
            </Heading>
            <Flex
              justifyContent={'center'}
              paddingTop={'30px'}
              paddingBottom={'30px'}
              backgroundColor={'white'}
              borderBottomColor={'gray.100'}
              borderBottomStyle={'solid'}
              borderBottomWidth={'2px'}
            >
              {jobCandidate ? (
                <ViewJobCandidateDetailsBio
                  requiresCredit={requiresCredit}
                  jobCandidate={jobCandidate}
                  scoreCard={true}
                  job={job}
                  withoutReviewsPath
                />
              ) : null}
            </Flex>
          </Box>
        }
        headerDividerIsHidden={true as IsHidden}
      >
        <ViewJobCandidateInterviewScoreCardsPage job={job} />

        <GridItem colSpan={2} width={'40%'}>
          <OverAllEvaluationFormField
            options={evaluation}
            placeholder="Overall Evaluation"
            name={ScoreCard.EVALUATION}
          />
          <OverAllEvaluationFormField
            options={nextStep}
            placeholder="Next Steps"
            name={ScoreCard.NEXT_STEP}
          />
        </GridItem>
        <Box mb={10}>
          <TextareaField label="Comments" />
        </Box>
      </SimpleModal>
    </>
  );
}

export default ViewJobCandidateInterviewScoreCardModal;
