import snakeCase from 'lodash/snakeCase';

import {
  FetchEducationsQuery,
  EducationFields,
  EducationId,
  EducationNanoId,
  EducationDegree,
  EducationSchoolName,
  EducationStudyField,
  EducationSchoolType,
  MayBeEducationStartDate,
  MayBeEducationEndDate,
  MayBeEducationCompany,
  MayBeEducationCompanyId,
  MayBeEducationState,
  MayBeEducationStateId,
  MayBeEducationCity,
  MayBeEducationCityId,
  EducationJobCandidateId,
  EducationCreatedAt
} from '../educationsTypes';

export interface FetchEducationsResponse {
  [EducationFields.ID]: EducationId;
  [EducationFields.NANO_ID]: EducationNanoId;
  [EducationFields.DEGREE]: EducationDegree;
  [EducationFields.SCHOOL_NAME]: EducationSchoolName;
  [EducationFields.STUDY_FIELD]: EducationStudyField;
  [EducationFields.SCHOOL_TYPE]: EducationSchoolType;
  [EducationFields.START_DATE]: MayBeEducationStartDate;
  [EducationFields.END_DATE]: MayBeEducationEndDate;
  [EducationFields.COMPANY]: MayBeEducationCompany;
  [EducationFields.COMPANY_ID]: MayBeEducationCompanyId;
  [EducationFields.STATE]: MayBeEducationState;
  [EducationFields.STATE_ID]: MayBeEducationStateId;
  [EducationFields.CITY]: MayBeEducationCity;
  [EducationFields.CITY_ID]: MayBeEducationCityId;
  [EducationFields.JOB_CANDIDATE_ID]: EducationJobCandidateId;
  [EducationFields.CREATED_AT]: EducationCreatedAt;
  [EducationFields.UPDATED_AT]: EducationCreatedAt;
}

export const fetchEducationsColumns = [
  EducationFields.ID,
  EducationFields.NANO_ID,
  EducationFields.DEGREE,
  EducationFields.SCHOOL_NAME,
  EducationFields.STUDY_FIELD,
  EducationFields.SCHOOL_TYPE,
  EducationFields.START_DATE,
  EducationFields.END_DATE,
  EducationFields.COMPANY_ID,
  EducationFields.STATE_ID,
  EducationFields.CITY_ID,
  EducationFields.JOB_CANDIDATE_ID,
  EducationFields.CREATED_AT
];

const fetchEducationsSBColumns = fetchEducationsColumns.map(
  (fetchEducationsColumn) => snakeCase(fetchEducationsColumn)
);

export const fetchEducationsQuery = `
  ${fetchEducationsSBColumns.join(',')},
  user:user_id(id,nano_id,name,image:image_id(id,nano_id,name,file,key,created_at)),
  company:company_id(id,nano_id,name,created_at,image:image_id(id,nano_id,name,file,key,created_at)),
  state:state_id(id,nano_id,name,iso_code),
  city:city_id(id,nano_id,name,label)
` as FetchEducationsQuery;
