import snakeCase from 'lodash/snakeCase';

import {
  FetchJobCandidateWorkflowStagesQuery,
  JobCandidateWorkflowStageFields,
  JobCandidateWorkflowStageId,
  JobCandidateWorkflowStageNanoId,
  JobCandidateWorkflowStageName,
  JobCandidateWorkflowStageArrangement,
  JobCandidateWorkflowStageColor,
  JobCandidateWorkflowStageEditable,
  JobCandidateWorkflowStageMovable,
  JobCandidateWorkflowStageRemovable,
  JobCandidateWorkflowStageTasks,
  JobCandidateWorkflowStageUser,
  JobCandidateWorkflowStageCreatedAt,
  JobCandidateWorkflowStageJobCandidates,
  JobCandidateWorkflowStageAllJobCandidates
} from '../jobCandidateWorkflowStagesTypes';
import { FetchJobCandidateWorkflowStagesApiQuery } from '../../../api/jobCandidateWorkflowStages/jobCandidateWorkflowStagesApiTypes';

export interface FetchJobCandidateWorkflowStagesResponse {
  workflowStage: {
    [JobCandidateWorkflowStageFields.ID]: JobCandidateWorkflowStageId;
    [JobCandidateWorkflowStageFields.NANO_ID]: JobCandidateWorkflowStageNanoId;
    [JobCandidateWorkflowStageFields.NAME]: JobCandidateWorkflowStageName;
    [JobCandidateWorkflowStageFields.ARRANGEMENT]: JobCandidateWorkflowStageArrangement;
    [JobCandidateWorkflowStageFields.COLOR]: JobCandidateWorkflowStageColor;
    [JobCandidateWorkflowStageFields.EDITABLE]: JobCandidateWorkflowStageEditable;
    [JobCandidateWorkflowStageFields.MOVABLE]: JobCandidateWorkflowStageMovable;
    [JobCandidateWorkflowStageFields.REMOVABLE]: JobCandidateWorkflowStageRemovable;
    [JobCandidateWorkflowStageFields.WORKFLOW_STAGE_TASKS]: JobCandidateWorkflowStageTasks;
    [JobCandidateWorkflowStageFields.JOB_CANDIDATES]: JobCandidateWorkflowStageJobCandidates;
    [JobCandidateWorkflowStageFields.ALL_JOB_CANDIDATES]: JobCandidateWorkflowStageAllJobCandidates;
    [JobCandidateWorkflowStageFields.USER]: JobCandidateWorkflowStageUser;
    [JobCandidateWorkflowStageFields.CREATED_AT]: JobCandidateWorkflowStageCreatedAt;
  };
}

export const fetchJobCandidateWorkflowStagesColumns = [
  JobCandidateWorkflowStageFields.ID,
  JobCandidateWorkflowStageFields.NANO_ID,
  JobCandidateWorkflowStageFields.NAME,
  JobCandidateWorkflowStageFields.ARRANGEMENT,
  JobCandidateWorkflowStageFields.COLOR,
  JobCandidateWorkflowStageFields.EDITABLE,
  JobCandidateWorkflowStageFields.MOVABLE,
  JobCandidateWorkflowStageFields.REMOVABLE,
  JobCandidateWorkflowStageFields.CREATED_AT
];

const fetchJobCandidateWorkflowStagesSBColumns =
  fetchJobCandidateWorkflowStagesColumns.map(
    (fetchJobCandidateWorkflowStagesColumn) =>
      snakeCase(fetchJobCandidateWorkflowStagesColumn)
  );

export const fetchJobCandidateWorkflowStagesQuery = `
  ${fetchJobCandidateWorkflowStagesSBColumns.join(',')},
  user:user_id(id,nano_id,name,image:image_id(id,nano_id,name,file,key,created_at)),
  workflow_stage_tasks!fk_rails_bd60de3db2(id,nano_id,name,arrangement,application,due_date),
  job_candidates!fk_rails_fdc143e851(id,nano_id,name,job_title,arrangement,search_type,search_type_label,disqualified_at,disqualification_reason,workflow_stage_task_scheduled_at,new_workflow_stage_task_at,viewed_at,workflow_stage_task_application_type,created_at,updated_at,due_date,status,job_id,phone,personal_phone,company_phone,workflow_stage_task:workflow_stage_task_id(id,nano_id,name,arrangement,application,due_date),state:state_id(id,nano_id,name,iso_code),city:city_id(id,nano_id,name,label),image:image_id(id,nano_id,name,file,key,created_at),user:user_id(id,name,image:image_id(id,nano_id,name,file,created_at)))
` as FetchJobCandidateWorkflowStagesQuery;

export const fetchJobCandidateWorkflowStagesApiQuery =
  fetchJobCandidateWorkflowStagesSBColumns.join(
    ','
  ) as FetchJobCandidateWorkflowStagesApiQuery;
