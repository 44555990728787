import snakeCase from 'lodash/snakeCase';

import {
  FetchJobScorecardEvaluationsQuery,
  JobScorecardEvaluationFields,
  JobScorecardEvaluationId,
  JobScorecardEvaluationNanoId,
  JobScorecardEvaluationCreatedAt
} from '../jobScorecardEvaluationsTypes';

export interface FetchJobScorecardEvaluationsResponse {
  [JobScorecardEvaluationFields.ID]: JobScorecardEvaluationId;
  [JobScorecardEvaluationFields.NANO_ID]: JobScorecardEvaluationNanoId;
  [JobScorecardEvaluationFields.CREATED_AT]: JobScorecardEvaluationCreatedAt;
}

export const fetchJobScorecardEvaluationsColumns = [
  JobScorecardEvaluationFields.ID,
  JobScorecardEvaluationFields.NANO_ID,
  JobScorecardEvaluationFields.CREATED_AT
];

const fetchJobScorecardEvaluationsSBColumns =
  fetchJobScorecardEvaluationsColumns.map(
    (fetchJobScorecardEvaluationsColumn) =>
      snakeCase(fetchJobScorecardEvaluationsColumn)
  );

export const fetchJobScorecardEvaluationsQuery =
  fetchJobScorecardEvaluationsSBColumns.join(
    ','
  ) as FetchJobScorecardEvaluationsQuery;
