import {
  FetchJobCandidatesCacheKey,
  FetchJobCandidateCacheKey,
  JobCandidateEmail,
  JobCandidateNanoId
} from './jobCandidatesTypes';

import { JobNanoId } from '../jobs/jobsTypes';
import { TalentPoolNanoId } from '../talentPools/talentPoolsTypes';
import { DepartmentNanoId } from '../departments/departmentsTypes';
import { WorkflowStageNanoId } from '../workflowStages/workflowStagesTypes';

export class JobCandidatesCache {
  static indexCacheKey() {
    return 'jobCandidates' as FetchJobCandidatesCacheKey;
  }

  static showCacheKey() {
    return 'jobCandidate' as FetchJobCandidateCacheKey;
  }

  static showJoCandidateCacheKey(jobCandidateNanoId: JobCandidateNanoId) {
    return `jobCandidate-${jobCandidateNanoId}` as FetchJobCandidateCacheKey;
  }

  static jobIndexCacheKey(jobNanoId: JobNanoId) {
    return `jobCandidates-job-${jobNanoId}` as FetchJobCandidatesCacheKey;
  }

  static jobWorkflowStageCacheKey(
    jobNanoId: JobNanoId,
    workflowStageNanoId: WorkflowStageNanoId
  ) {
    return `jobCandidates-job-workflowstage-${jobNanoId}-${workflowStageNanoId}` as FetchJobCandidatesCacheKey;
  }

  static departmentIndexCacheKey(departmentNanoId: DepartmentNanoId) {
    return `jobCandidates-department-${departmentNanoId}` as FetchJobCandidatesCacheKey;
  }

  static talentPoolIndexCacheKey(talentPoolNanoId: TalentPoolNanoId) {
    return `jobCandidates-talent-pool-${talentPoolNanoId}` as FetchJobCandidatesCacheKey;
  }

  static emailIndexCacheKey(jobCandidateEmail: JobCandidateEmail) {
    return `jobCandidates-email-${jobCandidateEmail}` as FetchJobCandidatesCacheKey;
  }
}
