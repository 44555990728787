import {
  JobScorecardEvaluationItemBffID,
  JobScorecardEvaluationItemBffNanoID,
  FetchJobScorecardEvaluationsBffURL,
  FetchJobScorecardEvaluationBffURL,
  FetchJobScorecardEvaluationByNanoIdBffURL,
  CreateJobScorecardEvaluationBffURL,
  UpdateJobScorecardEvaluationBffURL,
  DeleteJobScorecardEvaluationBffURL
} from './jobScorecardEvaluationsTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class JobScorecardEvaluationsBffRoutes extends BaseBffRoutes {
  protected static route = 'jobScorecardEvaluations';

  static fetchJobScorecardEvaluationsRoute() {
    return this.fetchItemsRoute<FetchJobScorecardEvaluationsBffURL>();
  }

  static fetchJobScorecardEvaluationsIndexRoute() {
    return this.fetchItemsIndexRoute<FetchJobScorecardEvaluationsBffURL>();
  }

  static fetchJobScorecardEvaluationRoute(
    jobScorecardEvaluationItemBffID: JobScorecardEvaluationItemBffID
  ) {
    return this.fetchItemRoute<FetchJobScorecardEvaluationBffURL>(
      jobScorecardEvaluationItemBffID
    );
  }

  static fetchJobScorecardEvaluationIndexRoute(
    jobScorecardEvaluationItemBffNanoID: JobScorecardEvaluationItemBffNanoID
  ) {
    return this.fetchItemIndexRoute<FetchJobScorecardEvaluationBffURL>(
      jobScorecardEvaluationItemBffNanoID
    );
  }

  static fetchJobScorecardEvaluationByNanoIdRoute(
    jobScorecardEvaluationItemBffNanoID: JobScorecardEvaluationItemBffNanoID
  ) {
    return this.fetchItemByNanoIdRoute<FetchJobScorecardEvaluationByNanoIdBffURL>(
      jobScorecardEvaluationItemBffNanoID
    );
  }

  static createJobScorecardEvaluationRoute() {
    return this.createItemRoute<CreateJobScorecardEvaluationBffURL>();
  }

  static updateJobScorecardEvaluationRoute(
    jobScorecardEvaluationItemBffNanoID:
      | JobScorecardEvaluationItemBffID
      | JobScorecardEvaluationItemBffNanoID
  ) {
    return this.updateItemRoute<UpdateJobScorecardEvaluationBffURL>(
      jobScorecardEvaluationItemBffNanoID
    );
  }

  static deleteJobScorecardEvaluationRoute(
    jobScorecardEvaluationItemBffNanoID: JobScorecardEvaluationItemBffNanoID
  ) {
    return this.deleteItemRoute<DeleteJobScorecardEvaluationBffURL>(
      jobScorecardEvaluationItemBffNanoID
    );
  }
}
