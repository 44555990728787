import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Box, Flex, HStack, Stack } from '@chakra-ui/layout';
import compact from 'lodash/compact';
import every from 'lodash/every';
import filter from 'lodash/filter';
import find from 'lodash/find';
import includes from 'lodash/includes';
import orderBy from 'lodash/orderBy';
import size from 'lodash/size';
import take from 'lodash/take';
import truncate from 'lodash/truncate';

import { ErrorMessage, I18nText } from '../../../../../types';

import {
  FetchSkillsCacheKey,
  FetchSkillsFilters,
  FetchSkillsPageSize,
  FetchSkillsSort,
  SkillId,
  SkillFields,
  SkillKinds,
  SkillName,
  FetchSkillsEnabled
} from '../../../skillsTypes';

import {
  FetchSkillBadgesCacheKey,
  FetchSkillBadgesFilters,
  FetchSkillBadgesPageSize,
  FetchSkillBadgesSort,
  SkillBadgeExperience,
  SkillBadgeFields,
  SkillBadgeJobCandidateId,
  SkillBadgeJobId,
  SkillBadgeJobResumeId,
  SkillBadgeJobResumeRecruiterId,
  SkillBadgeNanoId,
  SkillBadgeRating,
  SkillBadgeSkillId,
  SkillBadgeTalentPoolId,
  SkillBadgeProfileId,
  SkillBadgeUserId,
  SkillBadgeContactId,
  SkillBadgeRequirementsTypes,
  SkillBadgeRequirements
} from '../../../../skillBadges/skillBadgesTypes';

import {
  fetchSkillBadgesQuery,
  FetchSkillBadgesResponse
} from '../../../../skillBadges/queries/fetchSkillBadges.query';

import {
  fetchSkillsQuery,
  FetchSkillsResponse
} from '../../../queries/fetchSkills.query';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { usePaginatedSkills } from '../../../hooks/usePaginatedSkills';
import { usePaginatedSkillBadges } from '../../../../skillBadges/hooks/usePaginatedSkillBadges';
import { useCreateSkillBadge } from '../../../../skillBadges/hooks/useCreateSkillBadge';

import { SkillBadgeWrapper } from '../../../../skillBadges/components/SkillBadgeWrapper';
import { AddEditSkillBadgeForm } from '../../../../skillBadges/components/AddEditSkillBadgeForm';

import {
  AddSkillBadgeWrapper,
  AddSkillBadgeWrapperOnSelectSkill
} from '../../wrappers/AddSkillBadgeWrapper';
import { AddSkillDropdownWrapper } from '../../wrappers/AddSkillDropdownWrapper';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { Text } from '../../../../../helpers/Text';

import { generateNanoId } from '../../../../../utils/generateNanoId';

import {
  CommonSkillsBoxJob,
  CommonSkillsBoxJobCandidateId,
  CommonSkillsBoxJobResume,
  CommonSkillsBoxJobResumeRecruiter,
  CommonSkillsBoxTalentPool,
  CommonSkillsBoxProfile,
  CommonSkillsBoxContact
} from './CommonSkillsBox.types';

import { AddEditApplicantSkillBadgeForm } from '../../../../skillBadges/components/AddEditApplicantSkillBadgeForm';
import { JobSkillIds } from '../../../../jobs/jobsTypes';
import { OnSkillDropdownSelect } from '../../../helpers/SkillDropdown';
import { Spinner } from '@chakra-ui/react';

interface NewSkillType {
  id: SkillId;
  name: SkillName;
}

interface CommonSkillsBoxDefaultProps {
  label?: I18nText;
  actionLabel?: I18nText;
  errorMessage?: ErrorMessage;
  skillKind: SkillKinds;
  skillBadgesCacheKey: FetchSkillBadgesCacheKey;
  skillsCacheKey: FetchSkillsCacheKey;
  newSkillsCacheKey: FetchSkillsCacheKey;
  requiredSkillIds?: SkillId[];
  createOnly?: boolean;
  withoutAddBlock?: boolean;
  onSkillBadgesUpdated?: (skillBadges: FetchSkillBadgesResponse[]) => void;
  withoutEditDeleteFunctionality?: boolean;
  withoutPortal?: boolean;
  showSkillBadgeSkillNameOnly?: boolean;
  isIntakeForm?: boolean;
  maxSkillBadgesToShow?: number;
}

interface CommonSkillsBoxWithJobProps {
  job: CommonSkillsBoxJob;
  jobCandidateId?: never;
  jobResume?: never;
  jobResumeRecruiter?: never;
  jobResumeRecruiterJobSkillIds?: never;
  talentPool?: never;
  contact?: never;
  profile?: never;
  isApplicantWorkflow?: never;
}

interface CommonSkillsBoxWithJobCandidateProps {
  job?: never;
  jobCandidateId: CommonSkillsBoxJobCandidateId;
  jobResume?: never;
  jobResumeRecruiter?: never;
  jobResumeRecruiterJobSkillIds?: never;
  talentPool?: never;
  contact?: never;
  profile?: never;
  isApplicantWorkflow?: never;
}

interface CommonSkillsBoxWithJobCandidateApplicantWorkflowProps {
  job?: never;
  jobCandidateId: CommonSkillsBoxJobCandidateId;
  jobResume?: never;
  jobResumeRecruiter?: never;
  jobResumeRecruiterJobSkillIds?: never;
  talentPool?: never;
  contact?: never;
  profile?: never;
  isApplicantWorkflow?: true;
}

interface CommonSkillsBoxWithJobResumeProps {
  job?: never;
  jobCandidateId?: never;
  jobResume: CommonSkillsBoxJobResume;
  jobResumeRecruiter?: never;
  jobResumeRecruiterJobSkillIds?: never;
  talentPool?: never;
  contact?: never;
  profile?: never;
  isApplicantWorkflow?: never;
}

interface CommonSkillsBoxWithJobResumeRecruiterProps {
  job?: never;
  jobCandidateId?: never;
  jobResume?: never;
  jobResumeRecruiter: CommonSkillsBoxJobResumeRecruiter;
  jobResumeRecruiterJobSkillIds?: never;
  talentPool?: never;
  contact?: never;
  profile?: never;
  isApplicantWorkflow?: never;
}

interface CommonSkillsBoxWithJobResumeRecruiterApplicantWorkflowProps {
  job?: never;
  jobCandidateId?: never;
  jobResume?: never;
  jobResumeRecruiter: CommonSkillsBoxJobResumeRecruiter;
  jobResumeRecruiterJobSkillIds: JobSkillIds;
  talentPool?: never;
  contact?: never;
  profile?: never;
  isApplicantWorkflow: true;
}

interface CommonSkillsBoxWithTalentPoolProps {
  job?: never;
  jobCandidateId?: never;
  jobResume?: never;
  jobResumeRecruiter?: never;
  jobResumeRecruiterJobSkillIds?: never;
  talentPool: CommonSkillsBoxTalentPool;
  contact?: never;
  profile?: never;
  isApplicantWorkflow?: never;
}

interface CommonSkillsBoxWithProfileProps {
  job?: never;
  jobCandidateId?: never;
  jobResume?: never;
  jobResumeRecruiter?: never;
  jobResumeRecruiterJobSkillIds?: never;
  talentPool?: never;
  contact?: never;
  profile: CommonSkillsBoxProfile;
  isApplicantWorkflow?: never;
}

interface CommonSkillsBoxWithContactProps {
  job?: never;
  jobCandidateId?: never;
  jobResume?: never;
  jobResumeRecruiter?: never;
  jobResumeRecruiterJobSkillIds?: never;
  talentPool?: never;
  contact: CommonSkillsBoxContact;
  profile?: never;
  isApplicantWorkflow?: never;
}

type CommonSkillsBoxProps = CommonSkillsBoxDefaultProps &
  (
    | CommonSkillsBoxWithJobProps
    | CommonSkillsBoxWithJobCandidateProps
    | CommonSkillsBoxWithJobResumeProps
    | CommonSkillsBoxWithJobResumeRecruiterProps
    | CommonSkillsBoxWithTalentPoolProps
    | CommonSkillsBoxWithProfileProps
    | CommonSkillsBoxWithContactProps
    | CommonSkillsBoxWithJobResumeRecruiterApplicantWorkflowProps
    | CommonSkillsBoxWithJobCandidateApplicantWorkflowProps
  );

function CommonSkillsBox({
  label,
  actionLabel,
  errorMessage,
  skillKind,
  skillBadgesCacheKey,
  skillsCacheKey,
  newSkillsCacheKey,
  jobCandidateId,
  job,
  jobResume,
  jobResumeRecruiter,
  jobResumeRecruiterJobSkillIds,
  talentPool,
  profile,
  contact,
  requiredSkillIds,
  createOnly,
  withoutAddBlock,
  onSkillBadgesUpdated,
  withoutEditDeleteFunctionality,
  showSkillBadgeSkillNameOnly,
  withoutPortal,
  isApplicantWorkflow,
  isIntakeForm,
  maxSkillBadgesToShow
}: CommonSkillsBoxProps) {
  const currentUser = useCurrentUser();

  const { skillBadges, skillBadgesIsFetched, skillBadgesErrorMessage } =
    usePaginatedSkillBadges<FetchSkillBadgesResponse>({
      query: fetchSkillBadgesQuery,
      cacheKey: skillBadgesCacheKey,
      initialPageSize: 20 as FetchSkillBadgesPageSize,
      initialFilters: {
        ...(job?.id
          ? {
              [SkillBadgeFields.JOB_ID]: {
                operator: 'eq',
                value: `${job.id}`
              }
            }
          : {}),
        ...(jobResume?.id
          ? {
              [SkillBadgeFields.JOB_RESUME_ID]: {
                operator: 'eq',
                value: `${jobResume.id}`
              }
            }
          : {}),
        ...(jobResumeRecruiter?.id
          ? {
              [SkillBadgeFields.JOB_RESUME_RECRUITER_ID]: {
                operator: 'eq',
                value: `${jobResumeRecruiter.id}`
              }
            }
          : {}),
        ...(jobCandidateId
          ? {
              [SkillBadgeFields.JOB_CANDIDATE_ID]: {
                operator: 'eq',
                value: `${jobCandidateId}`
              }
            }
          : {}),
        ...(talentPool?.id
          ? {
              [SkillBadgeFields.TALENT_POOL_ID]: {
                operator: 'eq',
                value: `${talentPool.id}`
              }
            }
          : {}),
        ...(profile?.id
          ? {
              [SkillBadgeFields.PROFILE_ID]: {
                operator: 'eq',
                value: `${profile.id}`
              }
            }
          : {}),
        ...(contact?.id
          ? {
              [SkillBadgeFields.CONTACT_ID]: {
                operator: 'eq',
                value: `${contact.id}`
              }
            }
          : {}),
        'skill.kind': { operator: 'eq', value: skillKind }
      } as unknown as FetchSkillBadgesFilters,
      initialSort: {
        [SkillBadgeFields.CREATED_AT]: { ascending: false }
      } as unknown as FetchSkillBadgesSort,
      onSuccess: (data) => onSkillBadgesUpdated?.(data.data)
    });

  const { skills, skillsIsFetched, skillsErrorMessage } =
    usePaginatedSkills<FetchSkillsResponse>({
      query: fetchSkillsQuery,
      cacheKey: skillsCacheKey,
      enabled: !withoutAddBlock as FetchSkillsEnabled,
      initialPageSize: 10 as FetchSkillsPageSize,
      initialFilters: {
        [SkillFields.KIND]: { operator: 'eq', value: skillKind },
        ...(job
          ? {
              [SkillFields.ID]: {
                operator: 'in',
                value: job.skillIds?.join(',') || []
              }
            }
          : {}),
        ...(jobResume
          ? {
              [SkillFields.ID]: {
                operator: 'in',
                value: (
                  jobResume.jobResumesSkills?.map(({ skillId }) => skillId) ||
                  []
                ).join(',')
              }
            }
          : {}),
        ...(jobResumeRecruiter ||
        jobResumeRecruiterJobSkillIds ||
        jobCandidateId
          ? {
              [SkillFields.ID]: {
                operator: 'in',
                value:
                  requiredSkillIds?.join(',') ||
                  jobResumeRecruiter?.skillIds?.join(',') ||
                  jobResumeRecruiterJobSkillIds?.join(',') ||
                  []
              }
            }
          : {}),
        ...(jobCandidateId && !requiredSkillIds?.length
          ? { [SkillFields.SUGGESTED]: { operator: 'eq', value: true } }
          : {}),
        ...(talentPool
          ? {
              [SkillFields.ID]: {
                operator: 'in',
                value: talentPool.skillIds?.join(',') || []
              }
            }
          : {}),
        ...(profile
          ? {
              [SkillFields.ID]: {
                operator: 'in',
                value: profile.skillIds?.join(',') || []
              }
            }
          : {}),
        ...(contact
          ? {
              [SkillFields.ID]: {
                operator: 'in',
                value: contact.skillIds?.join(',') || []
              }
            }
          : {})
      } as unknown as FetchSkillsFilters,
      initialSort: {
        [SkillFields.CREATED_AT]: { ascending: false }
      } as unknown as FetchSkillsSort
    });

  const applicantSkillBadges = filter(
    skillBadges,
    (skillBadge) =>
      !!size(skillBadge.employmentSkillBadges) &&
      every(
        skillBadge.employmentSkillBadges,
        (employmentSkillBadge) =>
          !!employmentSkillBadge.usage && employmentSkillBadge.usage !== 'none'
      )
  );

  const skillNanoIds = isApplicantWorkflow
    ? applicantSkillBadges.map((skillBadge) => skillBadge.skill?.nanoId)
    : skillBadges.map((skillBadge) => skillBadge.skill?.nanoId);

  // const skillIds = compact([
  //   ...(isApplicantWorkflow
  //     ? applicantSkillBadges.map((skillBadge) => skillBadge.skillId)
  //     : skillBadges.map((skillBadge) => skillBadge.skillId)),
  //   ...skills.map((skill) => skill.id)
  // ]);
  const skillIds = compact([
    skillBadges?.map((skillBadge) => skillBadge.skillId),
    ...skills.map((skill) => skill.id)
  ]) as SkillId[];

  const [selectedSkillBadge, setSelectedSkillBadge] =
    useState<FetchSkillBadgesResponse | null>(null);

  const clearSelectedSkillBadge = useCallback<() => void>(
    () => setSelectedSkillBadge(null),
    [setSelectedSkillBadge]
  );

  const [selectedSkill, setSelectedSkill] =
    useState<FetchSkillsResponse | null>(null);

  const [newSkill, setNewSkill] = useState<NewSkillType | null>(null);

  const clearSkills = useCallback<() => void>(() => {
    setSelectedSkill(null);
    setNewSkill(null);
  }, [setSelectedSkill, setNewSkill]);

  const {
    createSkillBadgeErrorMessage,
    createSkillBadge,
    createSkillBadgeIsLoading
  } = useCreateSkillBadge({
    cacheKeys: [skillBadgesCacheKey]
  });

  const handleSelectSkill = useCallback<AddSkillBadgeWrapperOnSelectSkill>(
    async (skill) => {
      if (createOnly) {
        await createSkillBadge({
          rating: 'none' as SkillBadgeRating,
          experience: 'none' as SkillBadgeExperience,
          requirements:
            SkillBadgeRequirementsTypes.NONE as SkillBadgeRequirements,
          skillId: skill.id as SkillBadgeSkillId,
          jobId: job?.id as SkillBadgeJobId,
          jobCandidateId: jobCandidateId as SkillBadgeJobCandidateId,
          jobResumeId: jobResume?.id as SkillBadgeJobResumeId,
          jobResumeRecruiterId:
            jobResumeRecruiter?.id as SkillBadgeJobResumeRecruiterId,
          talentPoolId: talentPool?.id as SkillBadgeTalentPoolId,
          profileId: profile?.id as SkillBadgeProfileId,
          contactId: contact?.id as SkillBadgeContactId,
          userId: currentUser.id as SkillBadgeUserId,
          nanoId: generateNanoId<SkillBadgeNanoId>()
        });
        return;
      }

      if (isApplicantWorkflow) {
        const skillBadgeExists = find(
          skillBadges,
          (skillBadge) => skillBadge.skill?.nanoId === skill.nanoId
        );

        if (skillBadgeExists) {
          setSelectedSkillBadge(skillBadgeExists);
        } else {
          setSelectedSkill(skill);
        }
        return;
      }

      setSelectedSkill(skill);
    },
    [
      createOnly,
      job?.id,
      jobCandidateId,
      jobResume?.id,
      jobResumeRecruiter?.id,
      talentPool?.id,
      profile?.id,
      contact?.id,
      currentUser.id,
      createSkillBadge,
      setSelectedSkill,
      isApplicantWorkflow,
      skillBadges
    ]
  );
  const handleSelectNewSkill = useCallback<OnSkillDropdownSelect>(
    async (skill) => {
      if (createOnly) {
        await createSkillBadge({
          rating: 'none' as SkillBadgeRating,
          experience: 'none' as SkillBadgeExperience,
          requirements:
            SkillBadgeRequirementsTypes.NONE as SkillBadgeRequirements,
          skillId: skill?.id as SkillBadgeSkillId,
          jobId: job?.id as SkillBadgeJobId,
          jobCandidateId: jobCandidateId as SkillBadgeJobCandidateId,
          jobResumeId: jobResume?.id as SkillBadgeJobResumeId,
          jobResumeRecruiterId:
            jobResumeRecruiter?.id as SkillBadgeJobResumeRecruiterId,
          talentPoolId: talentPool?.id as SkillBadgeTalentPoolId,
          profileId: profile?.id as SkillBadgeProfileId,
          contactId: contact?.id as SkillBadgeContactId,
          userId: currentUser.id as SkillBadgeUserId,
          nanoId: generateNanoId<SkillBadgeNanoId>()
        });
        return;
      }

      setNewSkill(skill);
    },
    [
      createOnly,
      job?.id,
      jobCandidateId,
      jobResume?.id,
      jobResumeRecruiter?.id,
      talentPool?.id,
      profile?.id,
      contact?.id,
      currentUser.id,
      createSkillBadge,
      setNewSkill
    ]
  );

  const handleSelectSkillBadge = useCallback<
    (skillBadge: FetchSkillBadgesResponse) => void
  >(
    (skillBadge) => {
      if (createOnly) return;

      setSelectedSkillBadge(skillBadge);
    },
    [createOnly]
  );

  const addEditSkillBadgeFormFormWrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    (selectedSkill || newSkill) &&
      addEditSkillBadgeFormFormWrapper.current &&
      addEditSkillBadgeFormFormWrapper.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
  }, [selectedSkill, newSkill]);

  const checkIfSkillIsJobResumeRecruiterRequiredSkill = useCallback<
    (skillId: SkillId) => boolean | undefined
  >(
    (skillId) => {
      if (!jobResumeRecruiter) return false;
      const findJobResumeRecruiterSkill = find(
        jobResumeRecruiter.jobResumeRecruitersSkills,
        (jobResumeRecruiterSkill) => jobResumeRecruiterSkill.skillId === skillId
      );
      return findJobResumeRecruiterSkill?.required;
    },
    [jobResumeRecruiter]
  );

  const skillBadgesToShow = maxSkillBadgesToShow
    ? take(skillBadges, maxSkillBadgesToShow)
    : skillBadges;
  const skillBadgesLeft = size(skillBadges) - size(skillBadgesToShow);

  return (
    <Stack spacing={4}>
      {label ? (
        <Text textStyle="body2Regular" textTransform="uppercase">
          {label}
        </Text>
      ) : null}

      <AlertMessage message={skillBadgesErrorMessage} />

      <LoadingSkeleton loaded={skillBadgesIsFetched} count={2}>
        <Flex flexWrap="wrap" gap={3}>
          {skillBadgesToShow.map((skillBadge) =>
            showSkillBadgeSkillNameOnly ? (
              <Box
                key={skillBadge.nanoId}
                px={2}
                py={0}
                bg="white"
                border="1px solid"
                borderColor="gray.300"
                borderRadius="base"
              >
                <Text textStyle="body1Regular" color="gray.600">
                  {maxSkillBadgesToShow
                    ? truncate(skillBadge.skill?.name, {
                        length: 12,
                        omission: '...'
                      })
                    : skillBadge.skill?.name}
                </Text>
              </Box>
            ) : (
              <SkillBadgeWrapper
                key={skillBadge.nanoId}
                skillBadge={skillBadge}
                isEditing={selectedSkillBadge?.nanoId === skillBadge.nanoId}
                skillBadgesCacheKey={skillBadgesCacheKey}
                onEdit={setSelectedSkillBadge}
                withoutPortal={withoutPortal}
                withoutDeleteButton={checkIfSkillIsJobResumeRecruiterRequiredSkill(
                  skillBadge.skillId
                )}
                isApplicantWorkflow={isApplicantWorkflow}
                allowEditing={!withoutEditDeleteFunctionality}
                maxSkillBadgesToShow={maxSkillBadgesToShow}
                isJobCandidate={!!jobCandidateId}
              />
            )
          )}

          {maxSkillBadgesToShow && !!skillBadgesLeft ? (
            <Text textStyle="body2Regular" color="gray.600">
              + {skillBadgesLeft} more
            </Text>
          ) : null}
        </Flex>
      </LoadingSkeleton>

      {selectedSkillBadge ? (
        <Fragment>
          {isApplicantWorkflow ? (
            <AddEditApplicantSkillBadgeForm
              jobResumeRecruiterId={jobResumeRecruiter?.id}
              jobCandidateId={jobCandidateId}
              skillBadge={selectedSkillBadge}
              skillBadgesCacheKey={skillBadgesCacheKey}
              onAdd={clearSelectedSkillBadge}
              onDiscard={clearSelectedSkillBadge}
            />
          ) : (
            <AddEditSkillBadgeForm
              jobId={job?.id}
              jobCandidateId={jobCandidateId}
              jobResumeId={jobResume?.id}
              jobResumeRecruiterId={jobResumeRecruiter?.id}
              skillBadge={selectedSkillBadge}
              skillBadgesCacheKey={skillBadgesCacheKey}
              onAdd={clearSelectedSkillBadge}
              onDiscard={clearSelectedSkillBadge}
              isIntakeForm={isIntakeForm}
            />
          )}
        </Fragment>
      ) : null}

      {withoutAddBlock ? null : (
        <Fragment>
          <AlertMessage message={skillsErrorMessage} />

          <LoadingSkeleton loaded={skillsIsFetched} count={2}>
            <HStack spacing={2}>
              <Text>{actionLabel || 'Add applicable skills'}</Text>
              {createSkillBadgeIsLoading ? (
                <Spinner color="primary.500" size="xs" />
              ) : null}
            </HStack>
            <Flex flexWrap="wrap" gap={3}>
              {orderBy(skills, (skill) =>
                includes(requiredSkillIds, skill.id) ? 0 : 1
              ).map((skill) => (
                <Fragment key={skill.nanoId}>
                  {includes(skillNanoIds, skill.nanoId) ? null : (
                    <AddSkillBadgeWrapper
                      skill={skill}
                      // isSelected={selectedSkill?.nanoId === skill.nanoId}
                      isSelected={false} // set this to false to continue showing a red border and white background while a new skill is still being added
                      isRequired={includes(requiredSkillIds, skill.id)}
                      onSelectSkill={handleSelectSkill}
                    />
                  )}
                </Fragment>
              ))}

              {skillBadgesIsFetched && skillsIsFetched ? (
                <Box minW="90px">
                  <AddSkillDropdownWrapper
                    skillKind={skillKind}
                    skillIds={skillIds}
                    skillsCacheKey={newSkillsCacheKey}
                    skillBadges={skillBadges}
                    onSelectExistingSkill={handleSelectSkill}
                    onSelectExistingSkillBadge={handleSelectSkillBadge}
                    onSelectSkill={handleSelectNewSkill}
                  />
                </Box>
              ) : null}
              <AlertMessage
                message={createSkillBadgeErrorMessage || errorMessage}
              />
            </Flex>
          </LoadingSkeleton>
        </Fragment>
      )}

      {selectedSkill || newSkill ? (
        <Box ref={addEditSkillBadgeFormFormWrapper}>
          {isApplicantWorkflow ? (
            <AddEditApplicantSkillBadgeForm
              key={(selectedSkill || newSkill)?.id}
              jobResumeRecruiterId={jobResumeRecruiter?.id}
              jobCandidateId={jobCandidateId}
              skill={selectedSkill || newSkill || undefined}
              skillBadgesCacheKey={skillBadgesCacheKey}
              onAdd={clearSkills}
              onDiscard={clearSkills}
            />
          ) : (
            <AddEditSkillBadgeForm
              key={(selectedSkill || newSkill)?.id}
              jobId={job?.id}
              jobCandidateId={jobCandidateId}
              jobResumeId={jobResume?.id}
              jobResumeRecruiterId={jobResumeRecruiter?.id}
              talentPoolId={talentPool?.id}
              profileId={profile?.id}
              skill={selectedSkill || newSkill || undefined}
              skillBadgesCacheKey={skillBadgesCacheKey}
              onAdd={clearSkills}
              onDiscard={clearSkills}
              isIntakeForm={isIntakeForm}
            />
          )}
        </Box>
      ) : null}
    </Stack>
  );
}

export default CommonSkillsBox;
