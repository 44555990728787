import snakeCase from 'lodash/snakeCase';

import {
  FetchCertificationsQuery,
  CertificationFields,
  CertificationId,
  CertificationNanoId,
  CertificationName,
  CertificationStartDate,
  CertificationEndDate,
  CertificationUser,
  CertificationCreatedAt,
  CertificationJobCandidateId,
  MayBeCertificationCompanyId,
  MayBeCertificationCityId,
  MayBeCertificationCompany,
  MayBeCertificationCity,
  MayBeCertificationState,
  MayBeCertificationStateId,
  CertificationValidity,
  CertificationIssueDate,
  CertificationCredentialsId
} from '../certificationsTypes';

export interface FetchCertificationsResponse {
  [CertificationFields.ID]: CertificationId;
  [CertificationFields.NANO_ID]: CertificationNanoId;
  [CertificationFields.NAME]: CertificationName;
  [CertificationFields.START_DATE]: CertificationStartDate;
  [CertificationFields.END_DATE]: CertificationEndDate;
  [CertificationFields.VALIDITY]: CertificationValidity;
  [CertificationFields.ISSUE_DATE]: CertificationIssueDate;
  [CertificationFields.CREDENTIALS_ID]: CertificationCredentialsId;
  [CertificationFields.COMPANY_ID]: MayBeCertificationCompanyId;
  [CertificationFields.COMPANY]: MayBeCertificationCompany;
  [CertificationFields.STATE]: MayBeCertificationState;
  [CertificationFields.STATE_ID]: MayBeCertificationStateId;
  [CertificationFields.CITY_ID]: MayBeCertificationCityId;
  [CertificationFields.CITY]: MayBeCertificationCity;
  [CertificationFields.JOB_CANDIDATE_ID]: CertificationJobCandidateId;
  [CertificationFields.USER]: CertificationUser;
  [CertificationFields.CREATED_AT]: CertificationCreatedAt;
  [CertificationFields.UPDATED_AT]: CertificationCreatedAt;
}

export const fetchCertificationsColumns = [
  CertificationFields.ID,
  CertificationFields.NANO_ID,
  CertificationFields.NAME,
  CertificationFields.START_DATE,
  CertificationFields.END_DATE,
  CertificationFields.VALIDITY,
  CertificationFields.ISSUE_DATE,
  CertificationFields.CREDENTIALS_ID,
  CertificationFields.COMPANY_ID,
  CertificationFields.CITY_ID,
  CertificationFields.JOB_CANDIDATE_ID,
  CertificationFields.CREATED_AT
];

const fetchCertificationsSBColumns = fetchCertificationsColumns.map(
  (fetchCertificationsColumn) => snakeCase(fetchCertificationsColumn)
);

export const fetchCertificationsQuery = `
  ${fetchCertificationsSBColumns.join(',')},
  user:user_id(id,nano_id,name,image:image_id(id,nano_id,name,file,key,created_at)),
  company:company_id(id,nano_id,name,created_at,image:image_id(id,nano_id,name,file,key,created_at)),
  state:state_id(id,nano_id,name,iso_code),
  city:city_id(id,nano_id,name,label)
` as FetchCertificationsQuery;
