import { DateTime } from 'luxon';
import { DateTimeFormats, DateTimeUnitType } from './DateUtils.types';
import isNull from 'lodash/isNull';

export default class DateUtils {
  static now() {
    return DateTime.now().toISO();
  }

  static nowDateTime() {
    return DateTime.now();
  }

  static startOfToday() {
    return DateTime.now().startOf('day');
  }

  static endOfToday() {
    return DateTime.now().endOf('day');
  }

  static endOfTodayToISO() {
    return DateTime.now().endOf('day').toISO();
  }

  static startOfWeek(date: string | null): string | null {
    if (!date) {
      return null;
    }

    return DateTime.fromISO(date).startOf('week').toISO();
  }

  static endOfWeek(date: string | null): string | null {
    if (!date) {
      return null;
    }

    return DateTime.fromISO(date).endOf('week').toISO();
  }

  static startOfMonth(date: string | null): string | null {
    if (!date) {
      return null;
    }

    return DateTime.fromISO(date).startOf('month').toISO();
  }

  static endOfMonth(date: string | null): string | null {
    if (!date) {
      return null;
    }

    return DateTime.fromISO(date).endOf('month').toISO();
  }

  static calendarDay(date: string | null): DateTime | null {
    if (!date) {
      return null;
    }

    return DateTime.fromISO(date).startOf('day');
  }

  static formatDate(
    date: string | null,
    format = DateTimeFormats.DD_MMM_YYYY
  ): string | null {
    if (!date) {
      return null;
    }

    return DateTime.fromISO(date).toFormat(format);
  }

  static formatMonth(date: string | null): string | null {
    if (!date) {
      return null;
    }

    return DateTime.fromISO(date).toFormat(DateTimeFormats.MMM_YYYY);
  }

  static formatYear(date: string | null): string | null {
    if (!date) {
      return null;
    }

    return DateTime.fromISO(date).toFormat(DateTimeFormats.YYYY);
  }

  static toDate(date: string | null): Date | null {
    if (!date) {
      return null;
    }

    return DateTime.fromISO(date).toJSDate();
  }

  static fromJsDate(date: Date | null): Date | null {
    if (!date) {
      return null;
    }

    return DateTime.fromJSDate(date).toJSDate();
  }

  static hasSame(
    {
      date,
      otherDate
    }: {
      date: Date;
      otherDate: Date;
    },
    unit: DateTimeUnitType
  ): boolean {
    const mainDate = DateTime.fromJSDate(date);
    const comparedDate = DateTime.fromJSDate(otherDate);

    return mainDate.hasSame(comparedDate, unit);
  }

  static toISO(date: string | null): string | null {
    if (!date) {
      return null;
    }

    return DateTime.fromISO(date).toISO();
  }

  static toISODate(date: string | null): string | null {
    if (!date) {
      return null;
    }

    return DateTime.fromISO(date).toISODate();
  }

  static daysToToday(days: number | null): DateTime | null {
    if (isNull(days)) {
      return null;
    }

    return DateTime.now().minus({ days });
  }

  static monthsToToday(months: number | null): DateTime | null {
    if (isNull(months)) {
      return null;
    }

    return DateTime.now().minus({ months });
  }

  static daysFromTodayToISO(days: number | null): string | null {
    if (isNull(days)) {
      return null;
    }

    return DateTime.now().plus({ days }).toISO();
  }

  static daysToTodayToISO(days: number | null): string | null {
    if (isNull(days)) {
      return null;
    }

    return DateTime.now().minus({ days }).toISO();
  }

  static daysFromDateToISO(
    days: number | null,
    date: string | null
  ): string | null {
    if (isNull(days) || isNull(date)) {
      return null;
    }

    return DateTime.fromISO(date).plus({ days }).toISO();
  }

  static daysToDateToISO(
    days: number | null,
    date: string | null
  ): string | null {
    if (isNull(days) || isNull(date)) {
      return null;
    }

    return DateTime.fromISO(date).minus({ days }).toISO();
  }

  static monthsFromDateToISO(
    months: number | null,
    date: string | null
  ): string | null {
    if (isNull(months) || isNull(date)) {
      return null;
    }

    return DateTime.fromISO(date).plus({ months }).toISO();
  }

  static monthsToDateToISO(
    months: number | null,
    date: string | null
  ): string | null {
    if (isNull(months) || isNull(date)) {
      return null;
    }

    return DateTime.fromISO(date).minus({ months }).toISO();
  }

  static toRelative(date: string | null): string | null {
    if (!date) {
      return null;
    }

    return DateTime.fromISO(date).toRelative() === '0 seconds ago'
      ? 'just now'
      : DateTime.fromISO(date).toRelative({ style: 'short' });
  }

  static toRelativeCalendar(date: string | null): string | null {
    if (!date) {
      return null;
    }

    return DateTime.fromISO(date).toRelative() === '0 seconds ago'
      ? 'just now'
      : DateTime.fromISO(date).toRelativeCalendar({});
  }

  static toInput(date: string | null): string | null {
    if (!date) {
      return null;
    }

    return DateTime.fromISO(date).toFormat(DateTimeFormats.YYYY_LL_DD);
  }

  static toMonthInput(date: string | null): string | null {
    if (!date) {
      return null;
    }

    return DateTime.fromISO(date).toFormat('yyyy-MM');
  }

  static toInputFromJSDate(date: Date): string {
    return DateTime.fromJSDate(date).toFormat(DateTimeFormats.YYYY_LL_DD);
  }

  static toUnix(date: string | null) {
    if (!date) {
      return 0;
    }

    return DateTime.fromISO(date).toUnixInteger();
  }

  static endOfDay(date: string | null): DateTime | null {
    if (!date) {
      return null;
    }

    return DateTime.fromISO(date).endOf('day');
  }

  static setZone(date: string | null, zone = 'America/Cancun'): string | null {
    if (!date) {
      return null;
    }

    return DateTime.fromISO(date).setZone(zone).toISO();
  }

  static formatWithTimeZone(
    date: string | null,
    timeZone: string,
    format = 'yyyy/LL/dd'
  ): string | null {
    if (!date) {
      return null;
    }

    return DateTime.fromISO(date).setZone(timeZone).toFormat(format);
  }

  static getNextMonday(date: Date): Date {
    const nextMonday = new Date(date);
    const day = date.getDay();
    const daysToAdd = day === 6 ? 2 : 1; // If Saturday (6), add 2 days; if Sunday (0), add 1 day
    nextMonday.setDate(date.getDate() + daysToAdd);
    return nextMonday;
  }

  static isWeekend(date: Date): boolean {
    const day = date.getDay();
    return day === 0 || day === 6;
  }

  static isRecentlyModified({
    lastModified,
    durationMs
  }: {
    lastModified: Date | string | number;
    durationMs: number;
  }): boolean {
    const modifiedTime = new Date(lastModified).getTime();
    const now = Date.now();

    return now - modifiedTime <= durationMs;
  }
}
