import { useCallback, useState } from 'react';
import { Flex, Stack, StackDivider } from '@chakra-ui/react';

import { JobCandidateId } from '../../../../jobCandidates/jobCandidatesTypes';

import { JobResumeId } from '../../../../jobResumes/jobResumesTypes';

import { JobResumeRecruiterId } from '../../../../jobResumeRecruiters/jobResumeRecruitersTypes';

import {
  EducationFields,
  FetchEducationsFilters,
  FetchEducationsSort
} from '../../../educationsTypes';

import {
  fetchEducationsQuery,
  FetchEducationsResponse
} from '../../../queries/fetchEducations.query';

import { usePaginatedEducations } from '../../../../educations/hooks/usePaginatedEducations';

import { EducationsCache } from '../../../EducationsCache';

import { PlusIcon } from '../../../../../icons/PlusIcon';

import { EditEducationListItem } from '../../../../educations/components/lists/EditEducationListItem';
import { AddEditEducationForm } from '../../../../educations/components/forms/AddEditEducationForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { Heading } from '../../../../../helpers/Heading';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { PureButtonHelper } from '../../../../../helpers/buttons/PureButtonHelper';
import { DateUtils } from '../../../../../utils/DateUtils';

interface EducationsListFormWithJobCandidateProps {
  jobCandidateId: JobCandidateId;
  jobResumeId?: never;
  jobResumeRecruiterId?: never;
}

interface EducationsListFormWithJobResumeProps {
  jobCandidateId?: never;
  jobResumeId: JobResumeId;
  jobResumeRecruiterId?: never;
}

interface EducationsListFormWithJobResumeRecruiterProps {
  jobCandidateId?: never;
  jobResumeId?: never;
  jobResumeRecruiterId: JobResumeRecruiterId;
}

interface EducationsListFormRequiredProps {
  withoutHeading?: boolean;
  withStackDivider?: boolean;
  alignButtonToRight?: boolean;
}

type EducationsListFormProps = EducationsListFormRequiredProps &
  (
    | EducationsListFormWithJobCandidateProps
    | EducationsListFormWithJobResumeProps
    | EducationsListFormWithJobResumeRecruiterProps
  );

function EducationsListForm({
  jobCandidateId,
  jobResumeId,
  jobResumeRecruiterId,
  withoutHeading,
  withStackDivider,
  alignButtonToRight
}: EducationsListFormProps) {
  const educationsCacheKey =
    (jobCandidateId
      ? EducationsCache.jobCandidateListCacheKey(jobCandidateId)
      : null) ||
    (jobResumeId ? EducationsCache.jobResumeListCacheKey(jobResumeId) : null) ||
    (jobResumeRecruiterId
      ? EducationsCache.jobResumeRecruiterListCacheKey(jobResumeRecruiterId)
      : null) ||
    EducationsCache.indexCacheKey();

  const { educations, educationsIsFetched, educationsErrorMessage } =
    usePaginatedEducations<FetchEducationsResponse>({
      query: fetchEducationsQuery,
      cacheKey: educationsCacheKey,
      initialFilters: {
        ...(jobCandidateId
          ? {
              [EducationFields.JOB_CANDIDATE_ID]: {
                operator: 'eq',
                value: jobCandidateId
              }
            }
          : {}),
        ...(jobResumeId
          ? {
              [EducationFields.JOB_RESUME_ID]: {
                operator: 'eq',
                value: jobResumeId
              }
            }
          : {}),
        ...(jobResumeRecruiterId
          ? {
              [EducationFields.JOB_RESUME_RECRUITER_ID]: {
                operator: 'eq',
                value: jobResumeRecruiterId
              }
            }
          : {})
      } as unknown as FetchEducationsFilters,
      initialSort: {
        [EducationFields.CREATED_AT]: { ascending: false }
      } as unknown as FetchEducationsSort
    });

  const [
    addJobCandidateEducationFormIsOpen,
    setAddJobCandidateEducationFormIsOpen
  ] = useState<boolean>(false);

  const handleAddJobCandidateEducationFormOpen = useCallback(
    () => setAddJobCandidateEducationFormIsOpen(true),
    [setAddJobCandidateEducationFormIsOpen]
  );

  const handleAddJobCandidateEducationFormClose = useCallback(
    () => setAddJobCandidateEducationFormIsOpen(false),
    [setAddJobCandidateEducationFormIsOpen]
  );

  const findLastModifiedBeforeToday = useCallback<
    () => FetchEducationsResponse | undefined
  >(() => {
    return educations.find(
      (education) =>
        !DateUtils.isRecentlyModified({
          lastModified: education?.updatedAt,
          durationMs: 86400000 //last modified within the last day
        })
    );
  }, [educations]);

  return (
    <Stack
      spacing={8}
      divider={withStackDivider ? <StackDivider /> : undefined}
    >
      {withoutHeading ? null : (
        <Heading level="h3" color="#10002E" textAlign="center">
          Education
        </Heading>
      )}
      <LoadingSkeleton loaded={educationsIsFetched}>
        <AlertMessage message={educationsErrorMessage} />
        <Stack
          spacing={6}
          divider={withStackDivider ? <StackDivider /> : undefined}
        >
          {educations.map((education) => (
            <EditEducationListItem
              key={education.nanoId}
              education={education}
              educationsCacheKey={educationsCacheKey}
              stackSpacing={8}
              firstLastModifiedBeforeTodayId={
                findLastModifiedBeforeToday()?.nanoId
              }
            />
          ))}
        </Stack>
      </LoadingSkeleton>
      {addJobCandidateEducationFormIsOpen ? (
        <AddEditEducationForm
          type="new"
          jobCandidateId={jobCandidateId}
          jobResumeId={jobResumeId}
          jobResumeRecruiterId={jobResumeRecruiterId}
          educationsCacheKey={educationsCacheKey}
          isOpen={addJobCandidateEducationFormIsOpen}
          onClose={handleAddJobCandidateEducationFormClose}
          onSave={handleAddJobCandidateEducationFormClose}
        />
      ) : (
        <Flex>
          <PureButtonHelper
            hierarchy="tertiary"
            size="medium"
            leftIcon={<PlusIcon color="gray.500" />}
            i18nText="Add Education"
            flexGrow={alignButtonToRight ? 0 : 1}
            ml={alignButtonToRight ? 'auto' : undefined}
            onClick={handleAddJobCandidateEducationFormOpen}
          />
        </Flex>
      )}
    </Stack>
  );
}

export default EducationsListForm;
