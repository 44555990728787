import { Flex } from '@chakra-ui/react';
import { JobCandidateNanoId } from '../../../../../jobCandidates/jobCandidatesTypes';
import {
  JobId,
  JobJobTitle,
  JobName,
  JobNanoId,
  MayBeJobCities,
  MayBeJobCity
} from '../../../../jobsTypes';
import { JobCandidateDetailsJobCandidate } from '../../ViewJobCandidateDetailsPage.types';
import { ViewJobCandidateDetailsStageTasksWidgetProvider } from '../../contexts/ViewJobCandidateDetailsStageTasksWidgetContext';
import { ViewJobCandidateDetailsAdvanceDiscontinue } from './components/ViewJobCandidateDetailsAdvanceDiscontinue';
import { ViewJobCandidateDetailsStageTasksManagerWrapper } from './components/ViewJobCandidateDetailsStageTasksManagerWrapper';

interface ViewJobCandidateDetailsStageTasksManagerProps {
  jobCandidateNanoId: JobCandidateNanoId;
  requiresCredit?: boolean;
  job: {
    id: JobId;
    nanoId: JobNanoId;
    name: JobName;
    jobTitle: JobJobTitle;
    cities: MayBeJobCities;
    city: MayBeJobCity;
  };
  jobCandidate: JobCandidateDetailsJobCandidate;
  isHiringPortalPath?: boolean;
  isCentered?: boolean;
}

export default function ViewJobCandidateDetailsStageTasksManager({
  job,
  isCentered,
  jobCandidate,
  requiresCredit,
  isHiringPortalPath,
  jobCandidateNanoId
}: ViewJobCandidateDetailsStageTasksManagerProps) {
  return (
    <Flex pos="relative">
      {isHiringPortalPath ? (
        <ViewJobCandidateDetailsAdvanceDiscontinue
          job={job}
          jobCandidate={jobCandidate}
        />
      ) : (
        <ViewJobCandidateDetailsStageTasksWidgetProvider
          jobNanoId={job.nanoId}
          jobCandidate={jobCandidate}
          jobCandidateNanoId={jobCandidateNanoId}
        >
          <ViewJobCandidateDetailsStageTasksManagerWrapper
            job={job}
            isCentered={isCentered}
            jobCandidate={jobCandidate}
            isHiringPortalPath={isHiringPortalPath}
            jobCandidateNanoId={jobCandidateNanoId}
            requiresCredit={requiresCredit}
          />
        </ViewJobCandidateDetailsStageTasksWidgetProvider>
      )}
    </Flex>
  );
}
