import {
  FetchJobScorecardEvaluationsQuery,
  FetchJobScorecardEvaluationsCountType,
  FetchJobScorecardEvaluationsPage,
  FetchJobScorecardEvaluationsPageSize,
  FetchJobScorecardEvaluationsFilters,
  FetchJobScorecardEvaluationsSort,
  FetchJobScorecardEvaluationsIndexQueryName,
  FetchJobScorecardEvaluationsIndexCountType,
  FetchJobScorecardEvaluationsIndexPage,
  FetchJobScorecardEvaluationsIndexPageSize,
  FetchJobScorecardEvaluationsIndexFilters,
  FetchJobScorecardEvaluationsIndexSort,
  FetchJobScorecardEvaluationQuery,
  FetchJobScorecardEvaluationIndexQueryName,
  CreateJobScorecardEvaluationError,
  UpdateJobScorecardEvaluationError,
  DeleteJobScorecardEvaluationError,
  JobScorecardEvaluationId,
  JobScorecardEvaluationNanoId,
  JobScorecardEvaluationName
} from './jobScorecardEvaluationsTypes';

import { BaseBffRequests } from '../BaseBffRequests';

import { JobScorecardEvaluationsBffRoutes } from './JobScorecardEvaluationsBffRoutes';

interface FetchJobScorecardEvaluationsRequestParams {
  query: FetchJobScorecardEvaluationsQuery;
  countType?: FetchJobScorecardEvaluationsCountType;
  page?: FetchJobScorecardEvaluationsPage;
  pageSize?: FetchJobScorecardEvaluationsPageSize;
  filters?: FetchJobScorecardEvaluationsFilters;
  sort?: FetchJobScorecardEvaluationsSort;
}

interface FetchJobScorecardEvaluationsIndexRequestParams {
  queryName: FetchJobScorecardEvaluationsIndexQueryName;
  countType?: FetchJobScorecardEvaluationsIndexCountType;
  page?: FetchJobScorecardEvaluationsIndexPage;
  pageSize?: FetchJobScorecardEvaluationsIndexPageSize;
  filters?: FetchJobScorecardEvaluationsIndexFilters;
  sort?: FetchJobScorecardEvaluationsIndexSort;
}

interface FetchJobScorecardEvaluationRequestParams {
  query: FetchJobScorecardEvaluationQuery;
}

interface FetchJobScorecardEvaluationIndexRequestParams {
  queryName: FetchJobScorecardEvaluationIndexQueryName;
}

export interface JobScorecardEvaluationRequestData {
  name?: JobScorecardEvaluationName;
}

export interface CreateJobScorecardEvaluationDefaultRequestData
  extends JobScorecardEvaluationRequestData {
  id?: JobScorecardEvaluationId;
  nanoId: JobScorecardEvaluationNanoId;
}

export type CreateJobScorecardEvaluationRequestData =
  CreateJobScorecardEvaluationDefaultRequestData;

export type UpdateJobScorecardEvaluationRequestData =
  JobScorecardEvaluationRequestData;

export interface DeleteJobScorecardEvaluationRequestData {
  jobScorecardEvaluationNanoId: JobScorecardEvaluationNanoId;
}

export interface CreateJobScorecardEvaluationErrorResponse {
  message: CreateJobScorecardEvaluationError;
}

export interface UpdateJobScorecardEvaluationErrorResponse {
  message: UpdateJobScorecardEvaluationError;
}

export interface DeleteJobScorecardEvaluationErrorResponse {
  message: DeleteJobScorecardEvaluationError;
}

export class JobScorecardEvaluationsBffRequests extends BaseBffRequests {
  static fetchJobScorecardEvaluations<T>(
    params: FetchJobScorecardEvaluationsRequestParams
  ) {
    return this.fetchItems<T>(
      JobScorecardEvaluationsBffRoutes.fetchJobScorecardEvaluationsRoute(),
      params
    );
  }

  static fetchJobScorecardEvaluationsIndex<T>(
    params: FetchJobScorecardEvaluationsIndexRequestParams
  ) {
    return this.fetchItemsIndex<T>(
      JobScorecardEvaluationsBffRoutes.fetchJobScorecardEvaluationsIndexRoute(),
      params
    );
  }

  static fetchJobScorecardEvaluation<T>(
    jobScorecardEvaluationId: JobScorecardEvaluationId,
    params: FetchJobScorecardEvaluationRequestParams
  ) {
    return this.fetchItem<T>(
      JobScorecardEvaluationsBffRoutes.fetchJobScorecardEvaluationRoute(
        jobScorecardEvaluationId
      ),
      params
    );
  }

  static fetchJobScorecardEvaluationIndex<T>(
    jobScorecardEvaluationNanoId: JobScorecardEvaluationNanoId,
    params: FetchJobScorecardEvaluationIndexRequestParams
  ) {
    return this.fetchItemIndex<T>(
      JobScorecardEvaluationsBffRoutes.fetchJobScorecardEvaluationIndexRoute(
        jobScorecardEvaluationNanoId
      ),
      params
    );
  }

  static fetchJobScorecardEvaluationByNanoId<T>(
    jobScorecardEvaluationNanoId: JobScorecardEvaluationNanoId,
    params: FetchJobScorecardEvaluationRequestParams
  ) {
    return this.fetchItemByNanoId<T>(
      JobScorecardEvaluationsBffRoutes.fetchJobScorecardEvaluationByNanoIdRoute(
        jobScorecardEvaluationNanoId
      ),
      params
    );
  }

  static createJobScorecardEvaluation<T>(
    createJobScorecardEvaluationData: CreateJobScorecardEvaluationRequestData
  ) {
    return this.createItem<T, CreateJobScorecardEvaluationRequestData>(
      JobScorecardEvaluationsBffRoutes.createJobScorecardEvaluationRoute(),
      createJobScorecardEvaluationData
    );
  }

  static async updateJobScorecardEvaluation<T>(
    updateJobScorecardEvaluationNanoId: JobScorecardEvaluationNanoId,
    updateJobScorecardEvaluationData: UpdateJobScorecardEvaluationRequestData
  ) {
    return this.updateItem<T, UpdateJobScorecardEvaluationRequestData>(
      JobScorecardEvaluationsBffRoutes.updateJobScorecardEvaluationRoute(
        updateJobScorecardEvaluationNanoId
      ),
      updateJobScorecardEvaluationData
    );
  }

  static async deleteJobScorecardEvaluation<T>(
    deleteJobScorecardEvaluationNanoId: JobScorecardEvaluationNanoId
  ) {
    return this.deleteItem<T>(
      JobScorecardEvaluationsBffRoutes.deleteJobScorecardEvaluationRoute(
        deleteJobScorecardEvaluationNanoId
      )
    );
  }
}
