import {
  FieldBrand,
  ItemID,
  ItemIDs,
  ItemUUID,
  ItemNanoId,
  ItemCreatedAt,
  MayBe
} from '../../../types';

import {
  CountryId,
  CountryNanoId,
  CountryName,
  CountryLabel
} from '../../countries/countriesTypes';

import {
  StateId,
  StateNanoId,
  StateName,
  StateLabel
} from '../../states/statesTypes';

import {
  UserId,
  UserNanoId,
  UserName,
  UserFullName,
  UserEmail,
  UserImage,
  UserRoles
} from '../../users/usersTypes';

export type JobScorecardEvaluationId = FieldBrand<
  ItemID,
  'JobScorecardEvaluationId'
>;
export type JobScorecardEvaluationIds = FieldBrand<
  ItemIDs,
  'JobScorecardEvaluationIds'
>;
export type JobScorecardEvaluationUuid = FieldBrand<
  ItemUUID,
  'JobScorecardEvaluationUuid'
>;
export type MayBeJobScorecardEvaluationUuid = MayBe<JobScorecardEvaluationUuid>;
export type JobScorecardEvaluationNanoId = FieldBrand<
  ItemNanoId,
  'JobScorecardEvaluationNanoId'
>;
export type MayBeJobScorecardEvaluationNanoId =
  MayBe<JobScorecardEvaluationNanoId>;
export type JobScorecardEvaluationName = FieldBrand<
  string,
  'JobScorecardEvaluationName'
>;
export type JobScorecardEvaluationLabel = FieldBrand<
  string,
  'JobScorecardEvaluationLabel'
>;
export type JobScorecardEvaluationUserId = FieldBrand<
  UserId,
  'JobScorecardEvaluationUserId'
>;
export type MayBeJobScorecardEvaluationUserId =
  MayBe<JobScorecardEvaluationUserId>;
export type JobScorecardEvaluationUser = FieldBrand<
  {
    id: UserId;
    nanoId: UserNanoId;
    name: UserName;
    fullName: UserFullName;
    email: UserEmail;
    image: UserImage;
    roles: UserRoles;
  },
  'JobScorecardEvaluationUser'
>;
export type MayBeJobScorecardEvaluationUser = MayBe<JobScorecardEvaluationUser>;
export type JobScorecardEvaluationStateId = FieldBrand<
  StateId,
  'JobScorecardEvaluationStateId'
>;
export type MayBeJobScorecardEvaluationStateId =
  MayBe<JobScorecardEvaluationStateId>;
export type JobScorecardEvaluationState = FieldBrand<
  {
    id: StateId;
    nanoId: StateNanoId;
    name: StateName;
    label: StateLabel;
  },
  'JobScorecardEvaluationState'
>;
export type MayBeJobScorecardEvaluationState =
  MayBe<JobScorecardEvaluationState>;
export type JobScorecardEvaluationCountryId = FieldBrand<
  CountryId,
  'JobScorecardEvaluationCountryId'
>;
export type MayBeJobScorecardEvaluationCountryId =
  MayBe<JobScorecardEvaluationCountryId>;
export type JobScorecardEvaluationCountry = FieldBrand<
  {
    id: CountryId;
    nanoId: CountryNanoId;
    name: CountryName;
    label: CountryLabel;
  },
  'CountryState'
>;
export type MayBeJobScorecardEvaluationCountry =
  MayBe<JobScorecardEvaluationCountry>;
export type JobScorecardEvaluationCreatedAt = FieldBrand<
  ItemCreatedAt,
  'JobScorecardEvaluationCreatedAt'
>;

export const enum JobScorecardEvaluationFields {
  ID = 'id',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  JOB_ID = 'jobId',
  JOB_CANDIDATE_ID = 'jobCandidateId',
  CREATED_AT = 'createdAt',
  EVALUATIONS_USER_FULLNAME = 'evaluationsUserFullname'
}
