import { ViewJobCandidateDetailsProfileOverviewJobFitScorecardField } from '../../main/jobs/pages/ViewJobCandidateDetailsPage/components/ViewJobCandidateDetailsProfileOverview/components/ViewJobCandidateDetailsProfileOverviewJobFitScorecard/ViewJobCandidateDetailsProfileOverviewJobFitScorecard';
import map from 'lodash/map';
import {
  FetchJobScorecardEvaluationsMeta,
  JobScorecardEvaluationBreakdown,
  JobScorecardEvaluationIndividualAssessment
} from '../../main/jobScorecardEvaluations/jobScorecardEvaluationsTypes/fetchJobScorecardEvaluations.types';

// Define a set of base colors to use for any scorecard type
export const baseColors = [
  {
    main: '#287CC9',
    shades: ['#5A9DE0', '#4B8CD8', '#3B7BCC', '#2A6BBF', '#1A5AB2']
  }, // Blue
  {
    main: '#AB56B2',
    shades: ['#D88BDA', '#C374C6', '#A85CA5', '#8D4494', '#722C83']
  }, // Purple
  {
    main: '#EEA401',
    shades: ['#FFC233', '#E6A500', '#D89200', '#CA8000', '#BC6E00']
  }, // Orange
  {
    main: '#507D7B',
    shades: ['#6A9F9D', '#5D908E', '#4F8180', '#417271', '#336362']
  }, // Teal
  {
    main: '#82BFD1',
    shades: ['#A1D4E3', '#8FC7D8', '#7DBBCF', '#6BAFC6', '#59A3BD']
  } // Light Blue
];

// Default color if we run out of colors
export const defaultColor = {
  main: '#718096',
  shades: ['#A0AEC0', '#8D9BB3', '#7A88A6', '#677599', '#54628C']
};

// Helper function to adjust color lightness
export const adjustColorLightness = (hex: string, amount: number): string => {
  // Convert hex to RGB
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);

  // Adjust each component
  r = Math.min(255, r + amount * 5);
  g = Math.min(255, g + amount * 5);
  b = Math.min(255, b + amount * 5);

  // Convert back to hex
  return `#${Math.round(r).toString(16).padStart(2, '0')}${Math.round(g)
    .toString(16)
    .padStart(2, '0')}${Math.round(b).toString(16).padStart(2, '0')}`;
};

// Function to generate additional shades if needed
export const generateAdditionalShades = (
  mainColor: string,
  existingShades: string[],
  count: number
): string[] => {
  if (existingShades.length >= count) {
    return existingShades.slice(0, count);
  }

  // Generate additional shades
  const result = [...existingShades];

  for (let i = existingShades.length; i < count; i++) {
    const lightnessAdjustment = 5 * (i - existingShades.length + 1);
    const newShade = adjustColorLightness(mainColor, lightnessAdjustment);
    result.push(newShade);
  }

  return result;
};

export const calculateChartValues = (
  scorecardData: ViewJobCandidateDetailsProfileOverviewJobFitScorecardField[],
  overallPercentageScore: number
) => {
  const totalValue = map(scorecardData, 'value').reduce(
    (sum, val) => sum + val,
    0
  );
  const normalizedValues = map(scorecardData, 'value').map((value) => {
    const proportion = value / totalValue;
    return proportion * overallPercentageScore;
  });
  const remainingPortion = 100 - overallPercentageScore;
  return { normalizedValues, remainingPortion };
};

export const getColorForScorecardType = (index: number) => {
  // Use the index to select a color from our base colors
  // This ensures consistent colors within a single view
  return baseColors[index % baseColors.length] || defaultColor;
};

export const transformEvaluationData = (
  jobScorecardEvaluationsMeta: FetchJobScorecardEvaluationsMeta,
  mockScorecardData: ViewJobCandidateDetailsProfileOverviewJobFitScorecardField[]
) => {
  if (!jobScorecardEvaluationsMeta?.summary?.evaluation_breakdown?.length) {
    return mockScorecardData; // Fallback to mock data if no real data
  }

  return jobScorecardEvaluationsMeta.summary.evaluation_breakdown.map(
    (evaluation: JobScorecardEvaluationBreakdown, index: number) => {
      // Get color based on index in the array
      const colorSet = getColorForScorecardType(index);

      // Calculate percentage for progress bar
      const percentage =
        (evaluation.rating_obtained / evaluation.total_obtainable_rating) * 100;

      // Transform individual assessments to subfields
      const subfields = evaluation.individual_assessments.map(
        (
          assessment: JobScorecardEvaluationIndividualAssessment,
          subIndex: number
        ) => {
          // Generate enough shades for all subfields
          const allShades = generateAdditionalShades(
            colorSet.main,
            colorSet.shades,
            evaluation.individual_assessments.length
          );

          return {
            label: assessment.scorecard_question,
            value: assessment.rating_obtained,
            totalObtainable: assessment.total_obtainable_rating,
            color: allShades[subIndex]
          };
        }
      );

      return {
        label: evaluation.scorecard_type.replace(/_/g, ' '),
        value: evaluation.rating_obtained,
        totalObtainable: evaluation.total_obtainable_rating,
        color: colorSet.main,
        percentage,
        subfields
      };
    }
  );
};
