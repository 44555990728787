import {
  MayBe,
  FieldBrand,
  DateTime,
  ItemID,
  ItemUUID,
  ItemNanoId,
  ItemCreatedAt,
  ItemIDs
} from '../../../types';

import {
  CityId,
  CityLabel,
  CityName,
  CityNanoId
} from '../../cities/citiesTypes';

import {
  CompanyId,
  CompanyName,
  CompanyNanoId
} from '../../companies/companiesTypes';

import {
  JobCandidateId,
  JobCandidateName,
  JobCandidateNanoId
} from '../../jobCandidates/jobCandidatesTypes';

import {
  JobResumeRecruiterId,
  JobResumeRecruiterName,
  JobResumeRecruiterNanoId
} from '../../jobResumeRecruiters/jobResumeRecruitersTypes';

import { StateId, StateName, StateNanoId } from '../../states/statesTypes';

import {
  UserId,
  UserNanoId,
  UserName,
  UserImage
} from '../../users/usersTypes';

export type CertificationId = FieldBrand<ItemID, 'CertificationId'>;
export type CertificationIds = FieldBrand<ItemIDs, 'CertificationIds'>;
export type CertificationUuid = FieldBrand<ItemUUID, 'CertificationUuid'>;
export type MayBeCertificationUuid = MayBe<CertificationUuid>;
export type CertificationNanoId = FieldBrand<ItemNanoId, 'CertificationNanoId'>;
export type MayBeCertificationNanoId = MayBe<CertificationNanoId>;
export type CertificationName = FieldBrand<string, 'CertificationName'>;
export type CertificationValidity = FieldBrand<
  DateTime,
  'CertificationValidity'
>;
export type MayBeCertificationValidity = MayBe<CertificationValidity>;
export type CertificationStartDate = FieldBrand<
  DateTime,
  'CertificationStartDate'
>;
export type MayBeCertificationStartDate = MayBe<CertificationStartDate>;
export type CertificationEndDate = FieldBrand<DateTime, 'CertificationEndDate'>;
export type MayBeCertificationEndDate = MayBe<CertificationEndDate>;
export type CertificationIssueDate = FieldBrand<
  DateTime,
  'CertificationIssueDate'
>;
export type MayBeCertificationIssueDate = MayBe<CertificationIssueDate>;
export type CertificationCredentialsId = FieldBrand<
  string,
  'CertificationCredentialsId'
>;
export type CertificationStateId = FieldBrand<StateId, 'CertificationStateId'>;
export type MayBeCertificationStateId = MayBe<CertificationStateId>;
export type CertificationState = FieldBrand<
  {
    id: StateId;
    nanoId: StateNanoId;
    name: StateName;
  },
  'CertificationJobCandidateState'
>;
export type MayBeCertificationState = MayBe<CertificationState>;
export type CertificationCityId = FieldBrand<CityId, 'CertificationCityId'>;
export type MayBeCertificationCityId = MayBe<CertificationCityId>;
export type CertificationCity = FieldBrand<
  {
    id: CityId;
    nanoId: CityNanoId;
    name: CityName;
    label: CityLabel;
  },
  'CertificationJobCandidateCity'
>;
export type MayBeCertificationCity = MayBe<CertificationCity>;
export type CertificationCompanyId = FieldBrand<
  CompanyId,
  'CertificationCompanyId'
>;
export type MayBeCertificationCompanyId = MayBe<CertificationCompanyId>;
export type CertificationCompany = FieldBrand<
  {
    id: CompanyId;
    nanoId: CompanyNanoId;
    name: CompanyName;
  },
  'CertificationJobCandidateCompany'
>;
export type MayBeCertificationCompany = MayBe<CertificationCompany>;
export type CertificationJobCandidateId = FieldBrand<
  JobCandidateId,
  'CertificationJobCandidateId'
>;
export type MayBeCertificationJobCandidateId =
  MayBe<CertificationJobCandidateId>;
export type CertificationJobCandidate = FieldBrand<
  {
    id: JobCandidateId;
    nanoId: JobCandidateNanoId;
    name: JobCandidateName;
  },
  'CertificationJobCandidate'
>;
export type CertificationJobResumeRecruiterId = FieldBrand<
  JobResumeRecruiterId,
  'CertificationJobResumeRecruiterId'
>;
export type MayBeCertificationJobResumeRecruiterId =
  MayBe<CertificationJobResumeRecruiterId>;
export type CertificationJobResumeRecruiter = FieldBrand<
  {
    id: JobResumeRecruiterId;
    nanoId: JobResumeRecruiterNanoId;
    name: JobResumeRecruiterName;
  },
  'CertificationJobResumeRecruiter'
>;
export type CertificationUserId = FieldBrand<UserId, 'CertificationUserId'>;
export type CertificationUser = FieldBrand<
  {
    id: UserId;
    nanoId: UserNanoId;
    name: UserName;
    image: UserImage;
  },
  'CertificationUser'
>;
export type CertificationCreatedAt = FieldBrand<
  ItemCreatedAt,
  'CertificationCreatedAt'
>;

export const enum CertificationFields {
  ID = 'id',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  NAME = 'name',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  VALIDITY = 'validity',
  CREDENTIALS_ID = 'credentialsId',
  ISSUE_DATE = 'issueDate',
  COMPANY_ID = 'companyId',
  COMPANY = 'company',
  STATE = 'state',
  STATE_ID = 'stateId',
  CITY_ID = 'cityId',
  CITY = 'city',
  USER_ID = 'userId',
  USER = 'user',
  JOB_CANDIDATE_ID = 'jobCandidateId',
  JOB_CANDIDATE = 'jobCandidate',
  JOB_RESUME_RECRUITER_ID = 'jobResumeRecruiterId',
  JOB_RESUME_RECRUITER = 'jobResumeRecruiter',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt'
}
