import {
  MayBe,
  FieldBrand,
  ItemID,
  ItemUUID,
  ItemNanoId,
  ItemCreatedAt
} from '../../../types';

import {
  JobCandidateArrangement,
  JobCandidateCity,
  JobCandidateDisqualificationReason,
  JobCandidateId,
  JobCandidateImage,
  JobCandidateJobTitle,
  JobCandidateName,
  JobCandidateNanoId,
  JobCandidateState,
  JobCandidateSearchType,
  JobCandidateSearchTypeLabel,
  JobCandidateWorkflowStageTask,
  JobCandidateWorkflowStageTaskApplicationType,
  MayBeJobCandidateWorkflowStageTaskScheduledAt,
  MayBeJobCandidateDueDate,
  MayBeJobCandidateDisqualifiedAt,
  MayBeJobCandidateNewWorkflowStageTaskAt,
  MayBeJobCandidateViewedAt,
  JobCandidateUser,
  JobCandidateStatus,
  JobCandidateCreatedAt,
  JobCandidateUpdatedAt,
  JobCandidatePersonalPhone,
  JobCandidatePhone,
  JobCandidateCompanyPhone,
  JobCandidateCompany,
  JobCandidateLinkedinUrl,
  JobCandidateCandidateShares,
  // JobCandidateWorkflowStageTaskId,
  JobCandidateCities,
  JobCandidateWorkflowStageTaskId
} from '../../jobCandidates/jobCandidatesTypes';

import { JobId } from '../../jobs/jobsTypes';

import {
  UserId,
  UserNanoId,
  UserName,
  UserImage
} from '../../users/usersTypes';
import {
  WorkflowStageTaskId,
  WorkflowStageTaskNanoId,
  WorkflowStageTaskName,
  WorkflowStageTaskDueDate,
  WorkflowStageTaskArrangement
} from '../../workflowStageTasks/workflowStageTasksTypes';

/* import {
  JobCandidateWorkflowStageTaskArrangement,
  JobCandidateWorkflowStageTaskDueDate,
  JobCandidateWorkflowStageTaskId,
  JobCandidateWorkflowStageTaskName,
  JobCandidateWorkflowStageTaskNanoId
} from '../../jobCandidateWorkflowStageTasks/jobCandidateWorkflowStageTasksTypes'; */

export type JobCandidateWorkflowStageId = FieldBrand<
  ItemID,
  'JobCandidateWorkflowStageId'
>;
export type MayBeJobCandidateWorkflowStageId =
  MayBe<JobCandidateWorkflowStageId>;
export type JobCandidateWorkflowStageUuid = FieldBrand<
  ItemUUID,
  'JobCandidateWorkflowStageUuid'
>;
export type MayBeJobCandidateWorkflowStageUuid =
  MayBe<JobCandidateWorkflowStageUuid>;
export type JobCandidateWorkflowStageNanoId = FieldBrand<
  ItemNanoId,
  'JobCandidateWorkflowStageNanoId'
>;
export type MayBeJobCandidateWorkflowStageNanoId =
  MayBe<JobCandidateWorkflowStageNanoId>;
export type JobCandidateWorkflowStageName = FieldBrand<
  string,
  'JobCandidateWorkflowStageName'
>;
export type JobCandidateWorkflowStageArrangement = FieldBrand<
  number,
  'JobCandidateWorkflowStageArrangement'
>;
export type JobCandidateWorkflowStageColor = FieldBrand<
  string,
  'JobCandidateWorkflowStageColor'
>;
export type JobCandidateWorkflowStageEditable = FieldBrand<
  boolean,
  'JobCandidateWorkflowStageEditable'
>;
export type JobCandidateWorkflowStageMovable = FieldBrand<
  boolean,
  'JobCandidateWorkflowStageMovable'
>;
export type JobCandidateWorkflowStageRemovable = FieldBrand<
  boolean,
  'JobCandidateWorkflowStageRemovable'
>;
export type JobCandidateWorkflowStageJobId = FieldBrand<
  JobId,
  'JobCandidateWorkflowStageJobId'
>;
export type JobCandidateWorkflowStageUserId = FieldBrand<
  UserId,
  'JobCandidateWorkflowStageUserId'
>;
export type JobCandidateWorkflowStageUser = FieldBrand<
  {
    id: UserId;
    nanoId: UserNanoId;
    name: UserName;
    image: UserImage;
  },
  'JobCandidateWorkflowStageUser'
>;
export type JobCandidateWorkflowStageTasks = FieldBrand<
  {
    id: WorkflowStageTaskId;
    nanoId: WorkflowStageTaskNanoId;
    name: WorkflowStageTaskName;
    dueDate: WorkflowStageTaskDueDate;
    arrangement: WorkflowStageTaskArrangement;
  }[],
  'JobCandidateWorkflowStageTasks'
>;
export type JobCandidateWorkflowStageJobCandidates = FieldBrand<
  {
    id: JobCandidateId;
    nanoId: JobCandidateNanoId;
    name: JobCandidateName;
    image: JobCandidateImage;
    jobTitle: JobCandidateJobTitle;
    user: JobCandidateUser;
    jobCandidateWorkflowStageTaskId: JobCandidateWorkflowStageTaskId;
    jobCandidateWorkflowStageTask: JobCandidateWorkflowStageTask;
    city: JobCandidateCity;
    cities: JobCandidateCities;
    state: JobCandidateState;
    arrangement: JobCandidateArrangement;
    searchType: JobCandidateSearchType;
    searchTypeLabel: JobCandidateSearchTypeLabel;
    disqualifiedAt: MayBeJobCandidateDisqualifiedAt;
    disqualificationReason: JobCandidateDisqualificationReason;
    jobCandidateWorkflowStageTaskScheduledAt: MayBeJobCandidateWorkflowStageTaskScheduledAt;
    newJobCandidateWorkflowStageTaskAt: MayBeJobCandidateNewWorkflowStageTaskAt;
    viewedAt: MayBeJobCandidateViewedAt;
    jobCandidateWorkflowStageTaskApplicationType: JobCandidateWorkflowStageTaskApplicationType;
    dueDate: MayBeJobCandidateDueDate;
    status: JobCandidateStatus;
    createdAt: JobCandidateCreatedAt;
    updatedAt: JobCandidateUpdatedAt;
    personalPhone: JobCandidatePersonalPhone;
    phone: JobCandidatePhone;
    companyPhone: JobCandidateCompanyPhone;
    company: JobCandidateCompany;
    linkedinUrl: JobCandidateLinkedinUrl;
    candidateShares: JobCandidateCandidateShares;
  }[],
  'JobCandidateWorkflowStageJobCandidates'
>;
export type JobCandidateWorkflowStageAllJobCandidates =
  JobCandidateWorkflowStageJobCandidates;
export type JobCandidateWorkflowStageCreatedAt = FieldBrand<
  ItemCreatedAt,
  'JobCandidateWorkflowStageCreatedAt'
>;

export const jobCandidateWorkflowStageColors = [
  '#0580A4',
  '#257dc9',
  '#507d7b',
  '#6871e4',
  '#eda403',
  '#ab55b2',
  '#0aad91',
  '#ba3724',
  '#128e6b',
  '#f1ca4d',
  '#56cdf2'
];

export const enum JobCandidateWorkflowStageFields {
  ID = 'id',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  NAME = 'name',
  COLOR = 'color',
  MOVABLE = 'movable',
  EDITABLE = 'editable',
  REMOVABLE = 'removable',
  ARRANGEMENT = 'arrangement',
  JOB_CANDIDATES = 'jobCandidates',
  WORKFLOW_STAGE_TASKS = 'workflowStageTasks',
  ALL_JOB_CANDIDATES = 'allJobCandidates',
  JOB_CANDIDATE_ID = 'jobCandidateId',
  CREATED_AT = 'createdAt',
  JOB_ID = 'jobId',
  USER_ID = 'userId',
  USER = 'user'
}
