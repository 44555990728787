import {
  Flex,
  Grid,
  GridItem,
  HStack,
  Stack,
  StackDivider
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { Text } from '../../../../../../../../helpers/Text';
import { IconButton } from '../../../../../../../../helpers/buttons/IconButton';
import { ChevronDownIcon } from '../../../../../../../../icons/ChevronDownIcon';
import { ChevronLeftIcon } from '../../../../../../../../icons/ChevronLeftIcon';
import { ChevronRightIcon } from '../../../../../../../../icons/ChevronRightIcon';
import { ViewJobCandidateDetailsProfileCalendarEvents } from './components/ViewJobCandidateDetailsProfileCalendarEvents';
import { calendarEvents } from './calendarEvents';
import {
  DateTimeFormats,
  DateUtils
} from '../../../../../../../../utils/DateUtils';

function ViewJobCandidateDetailsProfileOverviewCalendar() {
  const [selectedDay, setSelectedDay] = useState(DateUtils.now());
  const startOfWeekDate = DateUtils.startOfWeek(selectedDay);

  const days = useMemo(
    () =>
      Array.from({ length: 7 }).map((_, i) =>
        DateUtils.daysFromDateToISO(i, startOfWeekDate)
      ),
    [startOfWeekDate]
  );

  const hasEvents = (day: string | null) => {
    return !!calendarEvents[
      DateUtils.formatDate(day, DateTimeFormats.YYYY_LL_DD) ?? ''
    ]?.length;
  };

  const isSelectedDay = (day: string | null) =>
    DateUtils.formatDate(day, DateTimeFormats.YYYY_LL_DD) ===
    DateUtils.formatDate(selectedDay, DateTimeFormats.YYYY_LL_DD);

  return (
    <>
      <Stack
        p={4}
        flex={1}
        bg="white"
        spacing={4}
        borderRadius="base"
        border="1px solid"
        borderColor="gray.200"
        divider={<StackDivider />}
      >
        <Flex alignItems="center" justifyContent="space-between" mx={-2}>
          <IconButton
            p={0}
            display="flex"
            size="extra-small"
            hierarchy="unstyled"
            aria-label="Previous Month"
            icon={<ChevronLeftIcon w={4} h={4} fill="gray.500" />}
            onClick={() =>
              setSelectedDay(
                DateUtils.startOfMonth(
                  DateUtils.monthsToDateToISO(1, selectedDay)
                ) as string
              )
            }
          />

          <HStack spacing={0}>
            <Text fontSize="md">
              {DateUtils.formatDate(selectedDay, DateTimeFormats.MMMM_YYYY)}
            </Text>

            <IconButton
              p={0}
              display="flex"
              size="extra-small"
              hierarchy="unstyled"
              aria-label="Previous Month"
              icon={<ChevronDownIcon w={4} h={4} fill="gray.500" />}
            />
          </HStack>

          <IconButton
            p={0}
            display="flex"
            size="extra-small"
            hierarchy="unstyled"
            aria-label="Next Month"
            icon={<ChevronRightIcon w={4} h={4} fill="gray.500" />}
            onClick={() =>
              setSelectedDay(
                DateUtils.startOfMonth(
                  DateUtils.monthsFromDateToISO(1, selectedDay)
                ) as string
              )
            }
          />
        </Flex>

        <Flex alignItems="flex-end" gap={1} mx={-2}>
          <IconButton
            p={0}
            h={8}
            display="flex"
            size="extra-small"
            hierarchy="unstyled"
            aria-label="Previous Week"
            icon={<ChevronLeftIcon w={4} h={4} fill="gray.500" />}
            onClick={() =>
              setSelectedDay(
                DateUtils.daysToDateToISO(1, selectedDay) as string
              )
            }
          />

          <Stack flex={1}>
            <Grid templateColumns="repeat(7, 1fr)" gap={0}>
              {days.map((day) => (
                <GridItem key={DateUtils.toISO(day)}>
                  <Text
                    color="gray.500"
                    lineHeight="1.25"
                    textAlign="center"
                    textTransform="uppercase"
                    fontWeight="medium"
                    fontSize="10px"
                  >
                    {DateUtils.formatDate(day, DateTimeFormats.CCC)}
                  </Text>
                </GridItem>
              ))}
            </Grid>

            <Grid
              gap={1}
              justifyItems="stretch"
              templateColumns="repeat(7, 1fr)"
            >
              {days.map((day) => (
                <GridItem
                  display="flex"
                  justifyContent="center"
                  key={DateUtils.toISO(day)}
                >
                  <Flex
                    w="full"
                    maxW={8}
                    aspectRatio="1/1"
                    transitionDuration="slow"
                    color={isSelectedDay(day) ? 'white' : 'gray.600'}
                    bg={
                      isSelectedDay(day)
                        ? 'primary.500'
                        : hasEvents(day)
                        ? 'primary.100'
                        : 'transparent'
                    }
                    onClick={() => day && setSelectedDay(day)}
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="full"
                    cursor="pointer"
                    _hover={{
                      bg: !isSelectedDay(day) && 'gray.100'
                    }}
                  >
                    <Text textAlign="center" textStyle="body2Medium">
                      {DateUtils.formatDate(day, DateTimeFormats.D)}
                    </Text>
                  </Flex>
                </GridItem>
              ))}
            </Grid>
          </Stack>

          <IconButton
            p={0}
            h={8}
            display="flex"
            size="extra-small"
            hierarchy="unstyled"
            aria-label="Next Week"
            icon={<ChevronRightIcon w={4} h={4} fill="gray.500" />}
            onClick={() =>
              setSelectedDay(
                DateUtils.daysFromDateToISO(1, selectedDay) as string
              )
            }
          />
        </Flex>

        <ViewJobCandidateDetailsProfileCalendarEvents
          selectedDay={selectedDay}
        />
      </Stack>
    </>
  );
}

export default ViewJobCandidateDetailsProfileOverviewCalendar;
