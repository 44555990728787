import { Grid, GridItem } from '@chakra-ui/react';
import { Loader } from '../../../../../../helpers/Loader';
import { JobCandidateWorkflowStagesCache } from '../../../../../jobCandidateWorkflowStages/JobCandidateWorkflowStagesCache';
import usePaginatedJobCandidateWorkflowStage from '../../../../../jobCandidateWorkflowStages/hooks/usePaginatedJobCandidateWorkflowStage/usePaginatedJobCandidateWorkflowStage';
import {
  FetchJobCandidateWorkflowStagesCacheKey,
  FetchJobCandidateWorkflowStagesEnabled,
  FetchJobCandidateWorkflowStagesFilters,
  JobCandidateWorkflowStageFields
} from '../../../../../jobCandidateWorkflowStages/jobCandidateWorkflowStagesTypes';
import {
  FetchJobCandidateWorkflowStagesResponse,
  fetchJobCandidateWorkflowStagesQuery
} from '../../../../../jobCandidateWorkflowStages/queries/fetchJobCandidateWorkflowStages.query';
import { JobCandidatesCache } from '../../../../../jobCandidates/JobCandidatesCache';
import { useFetchJobCandidateByNanoId } from '../../../../../jobCandidates/hooks/useFetchJobCandidateByNanoId';
import {
  FetchJobCandidateEnabled,
  JobCandidateId,
  JobCandidateNanoId
} from '../../../../../jobCandidates/jobCandidatesTypes';
import {
  FetchJobCandidateResponse,
  fetchJobCandidateQuery
} from '../../../../../jobCandidates/queries/fetchJobCandidate.query';
import { JobFunctionId } from '../../../../../jobFunctions/jobFunctionsTypes';
import { WorkflowStagesCache } from '../../../../../workflowStages/WorkflowStagesCache';
import { usePaginatedWorkflowStages } from '../../../../../workflowStages/hooks/usePaginatedWorkflowStages';
import {
  FetchWorkflowStagesResponse,
  fetchWorkflowStagesQuery
} from '../../../../../workflowStages/queries/fetchWorkflowStages.query';
import {
  FetchWorkflowStagesFilters,
  FetchWorkflowStagesSort,
  WorkflowStageFields
} from '../../../../../workflowStages/workflowStagesTypes';
import { JobId, JobNanoId } from '../../../../jobsTypes';
import { JobCandidateDetailsPageWorkflowStage } from '../../ViewJobCandidateDetailsPage.types';
import { ViewJobCandidateDetailsPipelineStages } from '../ViewJobCandidateDetailsPipelineStages';
import { ViewJobCandidateDetailsTalentPool } from '../ViewJobCandidateDetailsTalentPool';

interface ViewJobCandidateDetailsRightSidebarPipelineProps {
  job: {
    id: JobId;
    nanoId: JobNanoId;
    jobFunctionId: JobFunctionId;
  };
  jobCandidateNanoId: JobCandidateNanoId;
  isHiringPortalPage: boolean;
}

function ViewJobCandidateDetailsRightSidebarPipeline({
  job,
  jobCandidateNanoId,
  isHiringPortalPage
}: ViewJobCandidateDetailsRightSidebarPipelineProps) {
  const { workflowStages, workflowStagesIsFetched } =
    usePaginatedWorkflowStages<FetchWorkflowStagesResponse>({
      query: fetchWorkflowStagesQuery,
      cacheKey: WorkflowStagesCache.jobIndexCacheKey(job.nanoId),
      initialFilters: {
        [WorkflowStageFields.JOB_ID]: { operator: 'eq', value: job.id }
      } as unknown as FetchWorkflowStagesFilters,
      initialSort: {
        [WorkflowStageFields.ARRANGEMENT]: { ascending: true }
      } as unknown as FetchWorkflowStagesSort
    });

  const { jobCandidate, jobCandidateIsFetched } =
    useFetchJobCandidateByNanoId<FetchJobCandidateResponse>({
      jobCandidateNanoId: jobCandidateNanoId as JobCandidateNanoId,
      enabled: !!jobCandidateNanoId as FetchJobCandidateEnabled,
      cacheKey: JobCandidatesCache.showCacheKey(),
      query: fetchJobCandidateQuery
    });

  const { jobCandidateWorkflowStages, jobCandidateWorkflowStagesIsFetched } =
    usePaginatedJobCandidateWorkflowStage<FetchJobCandidateWorkflowStagesResponse>(
      {
        query: fetchJobCandidateWorkflowStagesQuery,
        enabled: (!!jobCandidate?.id &&
          !!job?.id) as FetchJobCandidateWorkflowStagesEnabled,
        cacheKey: JobCandidateWorkflowStagesCache.jobCandidateJobIndexCacheKey(
          jobCandidate?.id as JobCandidateId,
          job?.id as JobId
        ) as unknown as FetchJobCandidateWorkflowStagesCacheKey,
        initialFilters: {
          [JobCandidateWorkflowStageFields.JOB_CANDIDATE_ID]: {
            operator: 'eq',
            value: jobCandidate?.id
          },
          [JobCandidateWorkflowStageFields.JOB_ID]: {
            operator: 'eq',
            value: job?.id
          }
        } as unknown as FetchJobCandidateWorkflowStagesFilters
      }
    );

  const currentCandidateWorkflowStage = jobCandidateWorkflowStages?.[0]
    ?.workflowStage as unknown as JobCandidateDetailsPageWorkflowStage;

  return (
    <Grid gap={4} overflow="hidden" gridTemplateRows="1fr 1fr">
      <GridItem
        bg="white"
        pointerEvents="all"
        border="1px solid"
        borderColor="gray.200"
        transitionDelay="slow"
        borderRadius="base"
        overflow="hidden"
        minH="250px"
      >
        <Loader
          loaded={
            jobCandidateIsFetched &&
            workflowStagesIsFetched &&
            jobCandidateWorkflowStagesIsFetched
          }
        >
          {jobCandidate && currentCandidateWorkflowStage && (
            <ViewJobCandidateDetailsPipelineStages
              job={job}
              jobCandidate={jobCandidate}
              workflowStages={workflowStages}
              isHiringPortalPage={isHiringPortalPage}
              currentCandidateWorkflowStage={currentCandidateWorkflowStage}
            />
          )}
        </Loader>
      </GridItem>

      <GridItem
        bg="white"
        pointerEvents="all"
        border="1px solid"
        borderColor="gray.200"
        borderRadius="base"
        overflow="hidden"
        minH="250px"
      >
        <ViewJobCandidateDetailsTalentPool job={job} />
      </GridItem>
    </Grid>
  );
}

export default ViewJobCandidateDetailsRightSidebarPipeline;
