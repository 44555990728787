import { Flex, Stack, useDisclosure } from '@chakra-ui/react';
import { useEffect } from 'react';
import { IconButton } from '../../../../../../helpers/buttons/IconButton';
import { ChevronUpIcon } from '../../../../../../icons/ChevronUpIcon';
import { JobCandidateNanoId } from '../../../../../jobCandidates/jobCandidatesTypes';
import {
  JobId,
  JobJobTitle,
  JobName,
  JobNanoId,
  MayBeJobCities,
  MayBeJobCity
} from '../../../../jobsTypes';
import { JobCandidateDetailsJobCandidate } from '../../ViewJobCandidateDetailsPage.types';
import { ViewJobCandidateDetailsStageTasksManager } from '../ViewJobCandidateDetailsStageTasksManager';

interface ViewJobCandidateDetailsFloatingStageTasksManager {
  hasScrolled: boolean;
  jobCandidateNanoId: JobCandidateNanoId;
  job: {
    id: JobId;
    nanoId: JobNanoId;
    name: JobName;
    jobTitle: JobJobTitle;
    cities: MayBeJobCities;
    city: MayBeJobCity;
  };
  jobCandidate: JobCandidateDetailsJobCandidate;
}

export default function ViewJobCandidateDetailsFloatingStageTasksManager({
  job,
  jobCandidate,
  jobCandidateNanoId,
  hasScrolled
}: ViewJobCandidateDetailsFloatingStageTasksManager) {
  const {
    isOpen: isOpenStageManager,
    onClose: onCloseStageManager,
    onToggle: onToggleStageManager
  } = useDisclosure();

  const {
    isOpen: isHoveredButton,
    onOpen: onMouseHover,
    onClose: onMouseLeave
  } = useDisclosure();

  useEffect(() => {
    onCloseStageManager();
  }, [onCloseStageManager, hasScrolled]);

  return (
    <Stack
      left="50%"
      pos="fixed"
      zIndex={99}
      alignItems="center"
      transform="translateX(-50%)"
      bottom={hasScrolled ? 6 : -12}
      transitionDuration="slow"
    >
      {hasScrolled && isOpenStageManager && (
        <Flex
          p={6}
          bg="white"
          borderRadius="md"
          boxShadow="0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 0 25px 25px rgba(0, 0, 0, 0.04)"
        >
          <ViewJobCandidateDetailsStageTasksManager
            job={job}
            isCentered
            jobCandidate={jobCandidate}
            jobCandidateNanoId={jobCandidateNanoId}
          />
        </Flex>
      )}

      <Flex
        zIndex={9}
        position="relative"
        onMouseOver={onMouseHover}
        onMouseLeave={onMouseLeave}
      >
        <IconButton
          bg={isHoveredButton || isOpenStageManager ? 'primary.500' : 'white'}
          onClick={onToggleStageManager}
          borderRadius="full"
          icon={
            <ChevronUpIcon
              fill={
                isHoveredButton || isOpenStageManager ? 'white' : 'gray.500'
              }
            />
          }
          aria-label=""
          boxShadow="0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 0 25px 25px rgba(0, 0, 0, 0.04)"
        />
      </Flex>
    </Stack>
  );
}
