import {
  FetchJobScorecardEvaluationsCacheKey,
  FetchJobScorecardEvaluationCacheKey
} from './jobScorecardEvaluationsTypes';

export class JobScorecardEvaluationsCache {
  static indexCacheKey() {
    return 'jobScorecardEvaluations' as FetchJobScorecardEvaluationsCacheKey;
  }

  static showCacheKey() {
    return 'jobScorecardEvaluation' as FetchJobScorecardEvaluationCacheKey;
  }
}
