import {
  WorkflowStageTaskReminder,
  WorkflowStageTaskReminders
} from '../../workflowStageTasksTypes';

function renderWorkflowStageTaskDueDate(reminder: WorkflowStageTaskReminder) {
  switch (reminder) {
    case WorkflowStageTaskReminders.ONE_HOUR_BEFORE:
      return '1 hour before';
    case WorkflowStageTaskReminders.TWO_HOURS_BEFORE:
      return '2 hours before';
    case WorkflowStageTaskReminders.TWELVE_HOURS_BEFORE:
      return '12 hours before';
    case WorkflowStageTaskReminders.ONE_DAY_BEFORE:
      return '1 day before';
    case WorkflowStageTaskReminders.ONE_WEEK_BEFORE:
    default:
      return 'None';
  }
}

export default renderWorkflowStageTaskDueDate;
