import { Flex, HStack, StackDivider, useDisclosure } from '@chakra-ui/react';
import { Loader } from '../../../../../../../../helpers/Loader';
import { JobCandidateWorkflowStagesCache } from '../../../../../../../jobCandidateWorkflowStages/JobCandidateWorkflowStagesCache';
import usePaginatedJobCandidateWorkflowStage from '../../../../../../../jobCandidateWorkflowStages/hooks/usePaginatedJobCandidateWorkflowStage/usePaginatedJobCandidateWorkflowStage';
import {
  FetchJobCandidateWorkflowStagesCacheKey,
  FetchJobCandidateWorkflowStagesEnabled,
  FetchJobCandidateWorkflowStagesFilters,
  JobCandidateWorkflowStageFields
} from '../../../../../../../jobCandidateWorkflowStages/jobCandidateWorkflowStagesTypes';
import {
  fetchJobCandidateWorkflowStagesQuery,
  FetchJobCandidateWorkflowStagesResponse
} from '../../../../../../../jobCandidateWorkflowStages/queries/fetchJobCandidateWorkflowStages.query';
import {
  JobCandidateId,
  JobCandidateNanoId
} from '../../../../../../../jobCandidates/jobCandidatesTypes';
import {
  JobId,
  JobJobTitle,
  JobName,
  JobNanoId,
  MayBeJobCities,
  MayBeJobCity
} from '../../../../../../jobsTypes';
import {
  JobCandidateDetailsJobCandidate,
  JobCandidateDetailsPageWorkflowStage
} from '../../../../ViewJobCandidateDetailsPage.types';
import { ViewJobCandidateDetailsStageSection } from '../.././components/ViewJobCandidateDetailsStageSection';
import { ViewJobCandidateDetailsTasksSection } from '../.././components/ViewJobCandidateDetailsTasksSection';
import { ViewJobCandidateDetailsTaskRequireAttention } from '../../components/ViewJobCandidateDetailsTaskRequireAttention';

interface ViewJobCandidateDetailsStageTasksManagerWrapperProps {
  jobCandidateNanoId: JobCandidateNanoId;
  requiresCredit?: boolean;
  job: {
    id: JobId;
    nanoId: JobNanoId;
    name: JobName;
    jobTitle: JobJobTitle;
    cities: MayBeJobCities;
    city: MayBeJobCity;
  };
  jobCandidate: JobCandidateDetailsJobCandidate;
  isHiringPortalPath?: boolean;
  isCentered?: boolean;
}

export default function ViewJobCandidateDetailsStageTasksManagerWrapper({
  job,
  isCentered,
  jobCandidate,
  requiresCredit
}: ViewJobCandidateDetailsStageTasksManagerWrapperProps) {
  const {
    jobCandidateWorkflowStages,
    jobCandidateWorkflowStagesIsLoading,
    jobCandidateWorkflowStagesIsFetched
  } = usePaginatedJobCandidateWorkflowStage<FetchJobCandidateWorkflowStagesResponse>(
    {
      query: fetchJobCandidateWorkflowStagesQuery,
      enabled: (!!jobCandidate?.id &&
        !!job?.id) as FetchJobCandidateWorkflowStagesEnabled,
      cacheKey: JobCandidateWorkflowStagesCache.jobCandidateJobIndexCacheKey(
        jobCandidate?.id as JobCandidateId,
        job?.id as JobId
      ) as unknown as FetchJobCandidateWorkflowStagesCacheKey,
      initialFilters: {
        [JobCandidateWorkflowStageFields.JOB_CANDIDATE_ID]: {
          operator: 'eq',
          value: jobCandidate?.id
        },
        [JobCandidateWorkflowStageFields.JOB_ID]: {
          operator: 'eq',
          value: job?.id
        }
      } as unknown as FetchJobCandidateWorkflowStagesFilters
    }
  );

  const { isOpen: isOpenRequireAttention, onClose: onCloseRequireAttention } =
    useDisclosure({
      defaultIsOpen: true
    });

  const currentWorkflowStage = jobCandidateWorkflowStages?.[0]
    ?.workflowStage as unknown as JobCandidateDetailsPageWorkflowStage;

  return (
    <>
      <HStack
        w="lg"
        bg="gray.50"
        spacing={0}
        pos="relative"
        border="1px solid"
        borderRadius="base"
        borderColor="gray.200"
        transitionDuration="slow"
        justifyContent="flex-start"
        visibility={isOpenRequireAttention ? 'hidden' : 'visible'}
        opacity={isOpenRequireAttention ? 0 : 1}
        divider={<StackDivider />}
      >
        {jobCandidateWorkflowStagesIsFetched && jobCandidateWorkflowStages ? (
          <>
            <Flex justifyContent="stretch" w="50%">
              <ViewJobCandidateDetailsStageSection
                currentWorkflowStage={currentWorkflowStage}
                requiresCredit={requiresCredit}
                jobCandidate={jobCandidate}
                job={job}
              />
            </Flex>

            <Flex justifyContent="stretch" w="50%">
              {currentWorkflowStage?.workflowStageTasks && (
                <ViewJobCandidateDetailsTasksSection
                  workflowStageTasks={currentWorkflowStage.workflowStageTasks}
                />
              )}
            </Flex>
          </>
        ) : (
          <>
            {jobCandidateWorkflowStagesIsLoading && (
              <Flex p={3} h={20} mx="auto">
                <Loader
                  size={10}
                  loaded={jobCandidateWorkflowStagesIsFetched}
                />
              </Flex>
            )}
          </>
        )}
      </HStack>

      <Flex
        w="lg"
        h="full"
        zIndex={9}
        pos="absolute"
        display={isOpenRequireAttention ? 'flex' : 'none'}
        alignItems={isCentered ? 'center' : 'flex-start'}
        justifyContent={isCentered ? 'center' : 'flex-end'}
      >
        <ViewJobCandidateDetailsTaskRequireAttention
          reopenTask={onCloseRequireAttention}
          jobCandidate={jobCandidate}
          status="overdue"
        />
      </Flex>
    </>
  );
}
