import { Flex } from '@chakra-ui/layout';
import { PopoverBody, PopoverContent } from '@chakra-ui/popover';

import {
  MayBeSkillBadgeSkillLicense,
  SkillBadgeCertified,
  SkillBadgeExperience,
  SkillBadgeExperienceType,
  SkillBadgeRatingType,
  SkillBadgeRequirements,
  SkillBadgeSkill
} from '../../skillBadgesTypes';

import { CertifiedIcon } from '../../../../icons/CertifiedIcon';
import { JobsIcon } from '../../../../icons/JobsIcon';
import { StarIcon } from '../../../../icons/StarIcon';

import { Heading } from '../../../../helpers/Heading';
import { Text } from '../../../../helpers/Text';

import { SkillBadgeRequirementItem } from '../../helpers/SkillBadgeRequirementItem';

import { removeUnderscore } from '../../../../utils/removeUnderscore';
import { renderSkillBadgeExperience } from '../../utils/renderSkillBadgeExperience';
import { renderSkillBadgeRequirements } from '../../utils/renderSkillBadgeRequirements';
import { EmploymentSkillBadgeUsage } from '../../../employmentSkillBadges/employmentSkillBadgesTypes';
import { renderEmploymentSkillBadgeUsage } from '../../../employmentSkillBadges/utils/renderEmploymentSkillBadgeUsage';
import { renderSkillBadgeExperienceType } from '../../utils/renderSkillBadgeExperienceType';

interface SkillBadgeOnlyPopoverContentProps {
  name: SkillBadgeSkill['name'] | string;
  rating: SkillBadgeRatingType;
  experience: SkillBadgeExperience;
  certified: SkillBadgeCertified | boolean;
  requirements: SkillBadgeRequirements;
  skillLicense: MayBeSkillBadgeSkillLicense;
  withoutPortal?: boolean;
  withoutPopover?: boolean;
  recentUsage?: EmploymentSkillBadgeUsage | undefined;
  experienceType?: SkillBadgeExperienceType;
  hideRequirements?: boolean;
}

function SkillBadgeOnlyPopoverContent({
  name,
  rating,
  certified,
  experience,
  skillLicense,
  requirements,
  recentUsage,
  experienceType,
  hideRequirements
}: SkillBadgeOnlyPopoverContentProps) {
  const usage = renderEmploymentSkillBadgeUsage(
    recentUsage as EmploymentSkillBadgeUsage
  );

  return (
    <PopoverContent
      w="auto"
      color="white"
      bg="gray.900"
      borderColor="gray.200"
      boxShadow="0px 2px 2px rgba(23, 25, 26, 0.06)"
    >
      <PopoverBody p={4} minW="160px">
        <Flex flexDir="column" gap={1}>
          <Heading level="h4" textTransform="capitalize">
            {name}
          </Heading>

          <Text textStyle="body1Regular" color="rgba(255,255,255,0.8)">
            {!hideRequirements && (
              <>
                {requirements
                  ? renderSkillBadgeRequirements(requirements)
                  : 'Requirements'}
              </>
            )}

            {hideRequirements && (
              <>
                {experienceType &&
                  renderSkillBadgeExperienceType(experienceType)}{' '}
                {usage !== 'Unknown' && `/ ${usage}`}
              </>
            )}
          </Text>

          <Flex flexDir="column" gap={2}>
            {experience ? (
              <SkillBadgeRequirementItem
                label={renderSkillBadgeExperience(experience)}
                icon={<JobsIcon fill="white" w={5} h={5} />}
              />
            ) : null}

            {rating ? (
              <SkillBadgeRequirementItem
                label={removeUnderscore(rating)}
                icon={<StarIcon stroke="" fill="white" w={5} h={5} />}
              />
            ) : null}

            {certified ? (
              <SkillBadgeRequirementItem
                label={`${certified}`}
                icon={<CertifiedIcon fill="white" w={5} h={5} />}
              />
            ) : null}

            {skillLicense ? (
              <SkillBadgeRequirementItem
                label={skillLicense.name}
                icon={<CertifiedIcon fill="white" w={5} h={5} />}
              />
            ) : null}
          </Flex>
        </Flex>
      </PopoverBody>
    </PopoverContent>
  );
}

export default SkillBadgeOnlyPopoverContent;
