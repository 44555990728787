import {
  Avatar,
  AvatarGroup,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  useDisclosure
} from '@chakra-ui/react';
import { ArcElement, Chart, Legend, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Heading } from '../../../../../../../../helpers/Heading';
import { Text } from '../../../../../../../../helpers/Text';
import { NoScorecardIcon } from '../../../../../../../../icons/NoScorecardIcon';
import {
  ViewJobCandidateDetailsCandidate,
  ViewJobCandidateDetailsJobType
} from '../../../../pages/ViewJobCandidateDetailsProfilePage/ViewJobCandidateDetailsProfilePage.types';
import map from 'lodash/map';
import { ViewJobCandidateDetailsProfileOverviewJobFitScorecardItem } from '../ViewJobCandidateDetailsProfileOverviewJobFitScorecardItem';
import { mockScorecardData } from './mockdata';
import { useEffect, useRef } from 'react';
import { Button } from '../../../../../../../../helpers/Button';
import {
  JobScorecardEvaluationFields,
  FetchJobScorecardEvaluationsSort,
  FetchJobScorecardEvaluationsFilters
} from '../../../../../../../jobScorecardEvaluations/jobScorecardEvaluationsTypes';
import { usePaginatedJobScorecardEvaluations } from '../../../../../../../jobScorecardEvaluations/hooks/usePaginatedJobScorecardEvaluations';
import { JobScorecardEvaluationsCache } from '../../../../../../../jobScorecardEvaluations/JobScorecardEvaluationsCache';
import { fetchJobScorecardEvaluationsQuery } from '../../../../../../../jobScorecardEvaluations/queries/fetchJobScorecardEvaluations.query';
import {
  calculateChartValues,
  transformEvaluationData
} from '../../../../../../../../utils/scorecard';

Chart.register(ArcElement, Tooltip, Legend);

export type ViewJobCandidateDetailsProfileOverviewJobFitScorecardSubField = {
  label: string;
  value: number;
  totalObtainable: number;
  color: string;
};

export type ViewJobCandidateDetailsProfileOverviewJobFitScorecardField = {
  label: string;
  value: number;
  totalObtainable: number;
  color: string;
  percentage?: number;
  subfields: ViewJobCandidateDetailsProfileOverviewJobFitScorecardSubField[];
};

interface ViewJobCandidateDetailsProfileOverviewJobFitScorecardProps {
  jobCandidate: ViewJobCandidateDetailsCandidate;
  job: ViewJobCandidateDetailsJobType;
}

export interface JobScorecardEvaluation {
  id: string;
  createdAt: string;
  evaluations: {
    [key: string]: {
      comment: string;
      rating: number;
      scorecard_question: string;
    };
  };
  jobScorecard: {
    id: number;
    name: string;
    overallEvaluation: string;
    comments: string;
    positionFit: string;
    scorecardType: string;
  };
  user: {
    id: number;
    image: {
      file: string;
    };
    fullName: string;
  };
}

function ViewJobCandidateDetailsProfileOverviewJobFitScorecard({
  jobCandidate,
  job
}: ViewJobCandidateDetailsProfileOverviewJobFitScorecardProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const popoverTriggerRef = useRef<HTMLDivElement>(null);

  const {
    jobScorecardEvaluations,
    jobScorecardEvaluationsMeta,
    jobScorecardEvaluationsIsFetched,
    changeJobScorecardEvaluationsFilters
  } = usePaginatedJobScorecardEvaluations({
    query: fetchJobScorecardEvaluationsQuery,
    cacheKey: JobScorecardEvaluationsCache.indexCacheKey(),
    initialFilters: {
      [JobScorecardEvaluationFields.JOB_ID]: {
        operator: 'eq',
        value: job.id
      },
      [JobScorecardEvaluationFields.JOB_CANDIDATE_ID]: {
        operator: 'eq',
        value: jobCandidate.id
      },
      withSummary: true
    } as unknown as FetchJobScorecardEvaluationsFilters,
    initialSort: {
      [JobScorecardEvaluationFields.ID]: { ascending: false }
    } as unknown as FetchJobScorecardEvaluationsSort
  });

  useEffect(() => {
    changeJobScorecardEvaluationsFilters({
      [JobScorecardEvaluationFields.JOB_ID]: {
        operator: 'eq',
        value: job.id
      },
      [JobScorecardEvaluationFields.JOB_CANDIDATE_ID]: {
        operator: 'eq',
        value: jobCandidate.id
      }
    });
  }, [jobCandidate.id, job.id, changeJobScorecardEvaluationsFilters]);

  const overallPercentageScore =
    jobScorecardEvaluationsMeta?.summary?.overall_percentage_evaluation_score ||
    0;

  const scorecardData = transformEvaluationData(
    jobScorecardEvaluationsMeta,
    mockScorecardData
  );

  const { normalizedValues, remainingPortion } = calculateChartValues(
    scorecardData,
    overallPercentageScore
  );

  // Create the data for the chart
  const data = {
    datasets: [
      {
        data: [...normalizedValues, remainingPortion],
        backgroundColor: [...map(scorecardData, 'color'), '#F4F6F7'],
        hoverOffset: 0,
        borderWidth: 0
      }
    ]
  };

  const options = {
    plugins: {
      tooltip: { enabled: false },
      legend: { display: false }
    },
    cutout: '75%',
    maintainAspectRatio: false,
    devicePixelRatio: 2,
    responsive: true
  };

  useEffect(() => {
    const currentTarget = popoverTriggerRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) {
          onClose();
        }
      },
      { threshold: 0.25 }
    );

    if (currentTarget) {
      observer.observe(currentTarget);
    }

    return () => {
      if (currentTarget) {
        observer.unobserve(currentTarget);
      }
    };
  }, [onClose]);

  const hasReviews =
    jobScorecardEvaluationsIsFetched && jobScorecardEvaluations.length > 0;

  const reviewersAvatars = (
    jobScorecardEvaluations as JobScorecardEvaluation[]
  )?.map((evaluation) => evaluation.user.image?.file);

  const getFitText = (score: number): string => {
    if (score < 65) return 'Not a fit';
    if (score < 70) return 'Poor fit';
    if (score < 80) return 'Consider candidacy';
    if (score < 90) return 'Good fit';
    if (score < 95) return 'Great fit';
    return "Hire'em now";
  };

  return (
    <Stack p={4} gap={3} flex={1} bg="white" borderRadius="base">
      <Flex gap={2} alignItems="center">
        <Heading
          m={0}
          p={0}
          level="h4"
          fontSize="md"
          lineHeight={1}
          fontWeight="semibold"
        >
          Scorecards
        </Heading>
      </Flex>

      <Flex alignItems="center" justify="center">
        <Popover placement="right-start" isOpen={isOpen} onClose={onClose}>
          <PopoverTrigger>
            <Flex ref={popoverTriggerRef}>
              <Button
                h="auto"
                pos="relative"
                display="flex"
                whiteSpace="normal"
                hierarchy="unstyled"
                onClick={onOpen}
                bg="transparent"
              >
                <Flex w={36} zIndex={1} aspectRatio="square" pos="relative">
                  {hasReviews ? (
                    <>
                      <Doughnut data={data} options={options} />

                      <Flex
                        w={36}
                        h={36}
                        top={0}
                        pos="absolute"
                        flexDir="column"
                        alignItems="center"
                        borderRadius="full"
                        justifyContent="center"
                        zIndex={-1}
                      >
                        <Text
                          color="gray.800"
                          lineHeight="shorter"
                          fontWeight="semibold"
                          fontSize="3xl"
                        >
                          {overallPercentageScore}
                        </Text>

                        <Text textStyle="body1Medium" color="gray.500">
                          out of 100
                        </Text>
                      </Flex>
                    </>
                  ) : (
                    <>
                      <Doughnut
                        data={{
                          datasets: [
                            {
                              data: [1],
                              hoverOffset: 0,
                              borderWidth: 0,
                              backgroundColor: ['#F4F6F7']
                            }
                          ]
                        }}
                        options={options}
                      />

                      <Flex
                        w={36}
                        h={36}
                        top={0}
                        pos="absolute"
                        flexDir="column"
                        textAlign="center"
                        alignItems="center"
                        borderRadius="full"
                        justifyContent="center"
                        zIndex={-1}
                      >
                        <Text w="24" textStyle="body1Medium" color="gray.600">
                          No score available
                        </Text>
                      </Flex>
                    </>
                  )}
                </Flex>
              </Button>
            </Flex>
          </PopoverTrigger>

          <Portal>
            <PopoverContent w="sm">
              <PopoverBody p={4}>
                <Heading level="h5" lineHeight="shorter">
                  Job Fit Score
                </Heading>

                {hasReviews ? (
                  <Flex pt={2} gap={4} flexDir="column">
                    <Flex gap={3} alignItems="baseline">
                      <Flex alignItems="baseline">
                        <Text
                          color="gray.800"
                          fontWeight="semibold"
                          fontSize="3xl"
                        >
                          {overallPercentageScore}
                        </Text>

                        <Text color="gray.500" fontWeight="medium">
                          /100
                        </Text>
                      </Flex>

                      <Text ml="auto" color="green.600" fontWeight="medium">
                        {getFitText(overallPercentageScore)}
                      </Text>
                    </Flex>

                    <Flex gap={3} flexDir="column">
                      {scorecardData.map((item) => (
                        <ViewJobCandidateDetailsProfileOverviewJobFitScorecardItem
                          key={item.label}
                          field={item}
                        />
                      ))}
                    </Flex>
                  </Flex>
                ) : (
                  <Flex gap={2} flexDir="column" alignItems="center" py={6}>
                    <NoScorecardIcon />

                    <Text
                      fontWeight="medium"
                      textAlign="center"
                      fontSize="md"
                      maxW="2xs"
                    >
                      No score available
                    </Text>

                    <Text
                      textStyle="body1Medium"
                      textAlign="center"
                      color="gray.600"
                      maxW="2xs"
                    >
                      Some text explainnig why the score isn’t available here
                    </Text>
                  </Flex>
                )}
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      </Flex>

      <Flex justifyContent="flex-end">
        <AvatarGroup spacing={-2}>
          {reviewersAvatars
            ? reviewersAvatars.map((avatar) => (
                <Avatar src={avatar} size="sm" key={avatar} />
              ))
            : null}
        </AvatarGroup>
      </Flex>
    </Stack>
  );
}

export default ViewJobCandidateDetailsProfileOverviewJobFitScorecard;
