import { ViewJobCandidateDetailsProfileOverviewJobFitScorecardField } from './ViewJobCandidateDetailsProfileOverviewJobFitScorecard';

export const mockScorecardData: ViewJobCandidateDetailsProfileOverviewJobFitScorecardField[] =
  [
    {
      label: 'Qualifications',
      value: 12,
      totalObtainable: 0,
      color: '#AB56B2',
      subfields: [
        { label: 'Degree', value: 5, color: '#D88BDA', totalObtainable: 0 },
        {
          label: 'Certifications',
          value: 4,
          color: '#C374C6',
          totalObtainable: 0
        },
        { label: 'Experience', value: 3, color: '#A85CA5', totalObtainable: 0 }
      ]
    },
    {
      label: 'Job fit',
      value: 8,
      totalObtainable: 0,
      color: '#287CC9',
      subfields: [
        { label: 'Skills', value: 4, color: '#5A9DE0', totalObtainable: 0 },
        {
          label: 'Cultural Fit',
          value: 2,
          color: '#4B8CD8',
          totalObtainable: 0
        },
        { label: 'Attitude', value: 2, color: '#3B7BCC', totalObtainable: 0 }
      ]
    },
    {
      label: 'Tenure',
      value: 12,
      totalObtainable: 0,
      color: '#EEA401',
      subfields: [
        {
          label: 'Previous Jobs',
          value: 3,
          color: '#FFC233',
          totalObtainable: 0
        },
        { label: 'Loyalty', value: 3, color: '#E6A500', totalObtainable: 0 },
        {
          label: 'Growth Potential',
          value: 3,
          color: '#D89200',
          totalObtainable: 0
        }
      ]
    },
    {
      label: 'Screening',
      value: 10,
      totalObtainable: 0,
      color: '#507D7B',
      subfields: [
        {
          label: 'Background Check',
          value: 4,
          color: '#6A9F9D',
          totalObtainable: 0
        },
        {
          label: 'Reference Check',
          value: 3,
          color: '#5D908E',
          totalObtainable: 0
        },
        {
          label: 'Initial Interview',
          value: 3,
          color: '#4F8180',
          totalObtainable: 0
        }
      ]
    },
    {
      label: 'Questions',
      value: 8,
      totalObtainable: 0,
      color: '#82BFD1',
      subfields: [
        {
          label: 'Technical Questions',
          value: 3,
          color: '#A1D4E3',
          totalObtainable: 0
        },
        {
          label: 'Behavioral Questions',
          value: 3,
          color: '#8FC7D8',
          totalObtainable: 0
        },
        {
          label: 'Problem-Solving',
          value: 2,
          color: '#7DBBCF',
          totalObtainable: 0
        }
      ]
    }
  ];
