import { Flex, Grid } from '@chakra-ui/react';
import { useState } from 'react';
import { Button } from '../../../../../../helpers/Button';
import { ActivitiesIcon } from '../../../../../../icons/ActivitiesIcon';
import { Kanban } from '../../../../../../icons/Kanban';
import { JobCandidateNanoId } from '../../../../../jobCandidates/jobCandidatesTypes';
import { JobFunctionId } from '../../../../../jobFunctions/jobFunctionsTypes';
import { JobId, JobNanoId } from '../../../../jobsTypes';
import { ViewJobCandidateDetailsProfileOverviewCalendar } from '../ViewJobCandidateDetailsProfileOverview/components/ViewJobCandidateDetailsProfileOverviewCalendar';
import { ViewJobCandidateDetailsRightSidebarPipeline } from '../ViewJobCandidateDetailsRightSidebarPipeline';

interface ViewJobCandidateDetailsRightSidebarProps {
  job: {
    id: JobId;
    nanoId: JobNanoId;
    jobFunctionId: JobFunctionId;
  };
  isHiringPortalPage: boolean;
  jobCandidateNanoId: JobCandidateNanoId;
}

type ViewJobCandidateDetailsRightSidebarViewType = 'progress' | 'pipeline';

function ViewJobCandidateDetailsRightSidebar({
  job,
  jobCandidateNanoId,
  isHiringPortalPage
}: ViewJobCandidateDetailsRightSidebarProps) {
  const [currentView, setCurrentView] =
    useState<ViewJobCandidateDetailsRightSidebarViewType>('progress');

  const changeView = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { value } = e.currentTarget;

    setCurrentView(value as ViewJobCandidateDetailsRightSidebarViewType);
  };

  return (
    <Grid
      p={4}
      gap={3}
      top="72px"
      right={0}
      w="300px"
      zIndex={1}
      pos="fixed"
      pointerEvents="none"
      gridTemplateRows="max-content 1fr"
      h="calc(100vh - 72px)"
    >
      <Grid
        p={3}
        gap={2}
        bg="white"
        pointerEvents="all"
        border="1px solid"
        borderColor="gray.200"
        transitionDelay="slow"
        borderRadius="base"
        gridTemplateColumns="1fr 1fr"
      >
        <Button
          py={1}
          size="small"
          fontSize="sm"
          boxShadow="none"
          hierarchy="ghost"
          value="progress"
          fontWeight="medium"
          onClick={changeView}
          leftIcon={<ActivitiesIcon w={5} h={5} />}
          color={currentView === 'progress' ? 'primary.500' : 'gray.700'}
          bg={currentView === 'progress' ? 'primary.100' : 'transparent'}
        >
          Progress
        </Button>

        <Button
          py={1}
          size="small"
          fontSize="sm"
          boxShadow="none"
          hierarchy="ghost"
          value="pipeline"
          fontWeight="medium"
          onClick={changeView}
          leftIcon={<Kanban w={4} h={4} />}
          color={currentView === 'pipeline' ? 'primary.500' : 'gray.700'}
          bg={currentView === 'pipeline' ? 'primary.100' : 'transparent'}
        >
          Pipeline
        </Button>
      </Grid>

      {currentView === 'progress' && (
        <Flex
          h="full"
          gap={4}
          flexDir="column"
          overflow="auto"
          pointerEvents="all"
        >
          <ViewJobCandidateDetailsProfileOverviewCalendar />

          {/* <ViewJobCandidateDetailsActivityGrid
            jobNanoId={job.nanoId}
            jobCandidateNanoId={jobCandidateNanoId}
            isHiringPortalPage={isHiringPortalPage}
            departmentNanoId={'' as DepartmentNanoId}
            isProspectPage={false}
          /> */}
        </Flex>
      )}

      {currentView === 'pipeline' && (
        <ViewJobCandidateDetailsRightSidebarPipeline
          isHiringPortalPage={isHiringPortalPage}
          jobCandidateNanoId={jobCandidateNanoId}
          job={job}
        />
      )}
    </Grid>
  );
}

export default ViewJobCandidateDetailsRightSidebar;
