import { Box, Flex, Stack } from '@chakra-ui/react';
import filter from 'lodash/filter';
import size from 'lodash/size';
import { Fragment } from 'react';
import { Text } from '../../../../../../../../helpers/Text';
import { DateUtils } from '../../../../../../../../utils/DateUtils';
import { SkillBadgeOnly } from '../../../../../../../skillBadges/helpers/SkillBadgeOnly';
import { SkillKinds } from '../../../../../../../skills/skillsTypes';
import { EditJobCandidateSkillsForm } from '../../../../../../components/modals/EditJobCandidateModal/tabs/EditJobCandidateModalProfileTab/components/forms/EditJobCandidateSkillsForm';
import { ViewJobCandidateDetailsPageTabSection } from '../../../../components/ViewJobCandidateDetailsPageTabSection';
import { ViewJobCandidateDetailsCandidate } from '../../ViewJobCandidateDetailsProfilePage.types';
import { usePaginatedSkillBadges } from '../../../../../../../skillBadges/hooks/usePaginatedSkillBadges';
import {
  fetchSkillBadgesQuery,
  FetchSkillBadgesResponse
} from '../../../../../../../skillBadges/queries/fetchSkillBadges.query';
import {
  FetchSkillBadgesFilters,
  FetchSkillBadgesPageSize,
  FetchSkillBadgesSort,
  SkillBadgeFields,
  SkillBadgeJobCandidateId
} from '../../../../../../../skillBadges/skillBadgesTypes';
import { SkillBadgesCache } from '../../../../../../../skillBadges/SkillBadgesCache';
import { Loader } from '../../../../../../../../helpers/Loader';

interface ViewCandidateDetailsSkillsProps {
  isProspect: boolean;
  jobCandidate: ViewJobCandidateDetailsCandidate;
  isEditing?: boolean;
  showComputerSkills?: boolean;
  showIndustryKnowledge?: boolean;
  hideRequirements?: boolean;
}

function ViewCandidateDetailsSkills({
  isEditing,
  isProspect,
  jobCandidate,
  showComputerSkills = true,
  showIndustryKnowledge = true,
  hideRequirements
}: ViewCandidateDetailsSkillsProps) {
  // const { skillBadges } = jobCandidate;

  // const computerSkills = filter(
  //   skillBadges,
  //   (skillBadge) => skillBadge.skill?.kind === SkillKinds.COMPUTER
  // );

  // const industrySkills = filter(
  //   skillBadges,
  //   (skillBadge) => skillBadge.skill?.kind === SkillKinds.INDUSTRY
  // );

  const { skillBadges, skillBadgesIsFetched } =
    usePaginatedSkillBadges<FetchSkillBadgesResponse>({
      query: fetchSkillBadgesQuery,
      cacheKey: SkillBadgesCache.jobCandidateCacheKey(
        jobCandidate.id as SkillBadgeJobCandidateId
      ),
      initialPageSize: 50 as FetchSkillBadgesPageSize,
      initialFilters: {
        [SkillBadgeFields.JOB_CANDIDATE_ID]: {
          operator: 'eq',
          value: `${jobCandidate.id}`
        }
      } as unknown as FetchSkillBadgesFilters,
      initialSort: {
        [SkillBadgeFields.CREATED_AT]: { ascending: false }
      } as unknown as FetchSkillBadgesSort
    });

  const computerSkills = filter(
    skillBadges,
    (skillBadge) => skillBadge.skill?.kind === SkillKinds.COMPUTER
  );
  const industrySkills = filter(
    skillBadges,
    (skillBadge) => skillBadge.skill?.kind === SkillKinds.INDUSTRY
  );

  return (
    <>
      {isEditing ? (
        <ViewJobCandidateDetailsPageTabSection
          title="Skills and Knowledge"
          updatedDate={DateUtils.formatDate(jobCandidate.updatedAt) || ''}
        >
          <EditJobCandidateSkillsForm jobCandidate={jobCandidate} spacing={4} />
        </ViewJobCandidateDetailsPageTabSection>
      ) : (
        <>
          <ViewJobCandidateDetailsPageTabSection
            title="Skills and Knowledge"
            updatedDate={DateUtils.formatDate(jobCandidate.updatedAt) || ''}
          >
            <Loader loaded={skillBadgesIsFetched}>
              {(size(computerSkills) > 0 || size(industrySkills) > 0) && (
                <Stack spacing={4}>
                  {showComputerSkills && size(computerSkills) > 0 ? (
                    <Fragment>
                      <Text textStyle="upperCase1Regular" color="gray.600">
                        computer skills
                      </Text>

                      <Flex gap={3} flexFlow="row wrap">
                        {computerSkills.map((skillBadge) => (
                          <Fragment key={skillBadge.nanoId}>
                            {isProspect ? (
                              <SkillBadgeOnly
                                key={skillBadge.nanoId}
                                name={skillBadge.skill.name}
                                experience={skillBadge.experience}
                                rating={skillBadge.rating}
                                certified={!!skillBadge.skillLicense}
                                requirements={skillBadge.requirements}
                                skillLicense={skillBadge.skillLicense}
                                employmentSkillBadges={
                                  skillBadge.employmentSkillBadges
                                }
                                experienceType={skillBadge.experienceType}
                                withoutPortal
                                hideRequirements={hideRequirements}
                              />
                            ) : (
                              <Box
                                key={skillBadge.nanoId}
                                px={2}
                                py={0}
                                bg="white"
                                border="1px solid"
                                borderColor="gray.300"
                                borderRadius="base"
                              >
                                <Text textStyle="body1Regular" color="gray.600">
                                  {skillBadge.skill?.name}
                                </Text>
                              </Box>
                            )}
                          </Fragment>
                        ))}
                      </Flex>
                    </Fragment>
                  ) : null}

                  {showIndustryKnowledge && size(industrySkills) > 0 ? (
                    <Fragment>
                      <Text textStyle="upperCase1Regular" color="gray.600">
                        industry knowledge
                      </Text>
                      <Flex gap={3} flexFlow="row wrap">
                        {industrySkills.map((skillBadge) => (
                          <Fragment key={skillBadge.nanoId}>
                            {isProspect ? (
                              <>
                                {skillBadge.experience === 'none' ? null : (
                                  <SkillBadgeOnly
                                    key={skillBadge.nanoId}
                                    name={skillBadge.skill.name}
                                    experience={skillBadge.experience}
                                    rating={skillBadge.rating}
                                    certified={!!skillBadge.skillLicense}
                                    requirements={skillBadge.requirements}
                                    skillLicense={skillBadge.skillLicense}
                                    employmentSkillBadges={
                                      skillBadge.employmentSkillBadges
                                    }
                                    experienceType={skillBadge.experienceType}
                                    withoutPortal
                                    hideRequirements={hideRequirements}
                                  />
                                )}
                              </>
                            ) : (
                              <Box
                                key={skillBadge.nanoId}
                                px={2}
                                py={0}
                                bg="white"
                                border="1px solid"
                                borderColor="gray.300"
                                borderRadius="base"
                              >
                                <Text textStyle="body1Regular" color="gray.600">
                                  {skillBadge.skill?.name}
                                </Text>
                              </Box>
                            )}
                          </Fragment>
                        ))}
                      </Flex>
                    </Fragment>
                  ) : null}
                </Stack>
              )}
            </Loader>
          </ViewJobCandidateDetailsPageTabSection>
        </>
      )}
    </>
  );
}

export default ViewCandidateDetailsSkills;
